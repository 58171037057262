<template>
  <div v-if="true" v-loading="loading">
    <page-heading
      heading="Редактирование процедуры"
    >
      <span slot="help"></span>
      <div slot="right"></div>
    </page-heading>



    <CompensationProcedureEditBaseSettings
      :procedure="procedure"
    ></CompensationProcedureEditBaseSettings>



    <component
      v-if="procedureSettingsTemplate"
      :is="procedureSettingsTemplate"
      :procedure="procedure"
    ></component>


  </div>
</template>
<script>
import requestSender from "@/api/base/requestSender";
import PageHeading from "@/components/PageHeading";
import Paginator from "@/components/Paginator";
import {paginator} from "@/mixins/paginator";
import {mapGetters} from "vuex";
import {sorter} from "@/mixins/sorter";
import DeletedCardsModal from "@/components/cards/DeletedCardsModal.vue";
import CompensationProcedureCreateModal
  from "@/componentsCompany/smz/compensation/procedures/CompensationProcedureCreateModal.vue";
import CompensationProcedureEditBaseSettings
  from "@/componentsCompany/smz/compensation/procedures/CompensationProcedureEditBaseSettings.vue";

export default {
  name: 'CompensationProcedureEdit',
  components: {
    CompensationProcedureEditBaseSettings,
    PageHeading,
  },
  mixins: [],
  computed: {
    ...mapGetters(['user']),
    procedureSettingsTemplate() {
      if( !this.procedure?.type ){
        return null;
      }
      return () => import(`@/componentsCompany/smz/compensation/procedures/editTemplates/${this.procedure.type}`);
    },
  },
  watch: {},
  data() {
    return {
      loading: false,

      procedure: {},

    }
  },
  mounted() {
    this.loadProcedure();
  },
  methods: {
    loadProcedure() {
      this.loading = true;

      requestSender('get', 'compensation-procedure/get', {
        id: parseInt(this.$route.params.id),
        expand: [].join(),
      })
        .then(data => {
          this.procedure = data.procedure;
        })
        .finally(() => {
          this.loading = false;
        });
    },


  }
}
</script>

<style scoped lang="scss"></style>