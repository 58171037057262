<template>
  <div>
    <el-dialog
      title="Выбор карт"
      destroy-on-close
      :visible.sync="show"
      :width="width"
      top="2vh"
      :close-on-click-modal="false"
    >
      <el-row>
        <cards-component
          hide-last-column
          selectable
          @selection-changed="selectedCards = $event"
        ></cards-component>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="show = false">Закрыть</el-button>
        <el-button
          type="primary"
          size="small"
          :disabled="!selectedCards.length"
          @click="chooseSelected"
        >
          Выбрать отмеченные
        </el-button>
      </div>
    </el-dialog>

    <span ref="wrapper">
            <slot name="reference"></slot>
        </span>
  </div>
</template>

<script>

import {off, on} from "element-ui/src/utils/dom";
import {paginator} from "@/mixins/paginator";
import Paginator from "@/components/Paginator";
import CardsComponent from "@/components/cards/CardsComponent";

export default {
  name: "select-cards-modal",
  components: {CardsComponent, Paginator},
  mixins: [paginator],
  props: {
    method: Function,
    params: Object,
    width: {
      type: String,
      default: '95%'
    },
    showModelFullName: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      show: false,
      loading: false,

      cards: [],
      selectedCards: [],
    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showDeleted);
    }
  },
  watch: {},
  methods: {
    showDeleted() {
      this.show = true;
    },

    chooseSelected() {
      this.$emit('cards-selected', this.selectedCards);
      this.show = false;
    },
  },

  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showDeleted);
    }
  }

}
</script>

<style>

</style>