<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="show"
      :width="width"
      :close-on-click-modal="false"
    >

      <div>
        Вы можете выгрузить папку, произвести изменения в excel и потом загрузить
        измененный файл сюда
      </div>

      <el-upload
        style="margin-top: 20px"
        ref="file-uploader"
        class=""
        drag
        action=""
        :auto-upload="false"
        :http-request="doFileUpload"
      >
        <i class="el-icon-upload" style="margin-top: 40px"></i>
        <div class="el-upload__text">Перетащите или нажмите для загрузки файла</div>
      </el-upload>


      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="show = false">Закрыть</el-button>
        <el-button
          type="primary"
          size="small"
          @click="upload"
        >
          Загрузить
        </el-button>
      </div>
    </el-dialog>

    <span ref="wrapper">
      <slot name="reference"></slot>
    </span>
  </div>
</template>

<script>

import {off, on} from "element-ui/src/utils/dom";

export default {
  name: "library-import-elements-modal",
  components: {},
  props: {
    title: {type: String, default: 'Импорт целей'},
    width: {type: String, default: '40%'},
    showModelFullName: {type: Boolean, default: false}
  },

  data() {
    return {
      show: false,
      loading: false,
    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  },
  watch: {},
  methods: {
    showModal() {
      this.loading = true;
      this.show = true;
    },

    upload() {
      this.$refs["file-uploader"].submit();
    },

    doFileUpload(params) {
      let form = new FormData();
      form.append('file', params.file);
      return this.$api.library.importElements(form)
        .then(data => {
          this.$refs["file-uploader"].clearFiles();
          this.show = false;
          this.$notify.success({
            title: 'Сохранено',
            message: 'Элементы успешно сохранены'
          });
          this.$emit('elements-imported');
          //TODO: сделать нормальную перезагрузку компонента, а не всю страницу
          setTimeout(this.$router.go, 400);
        })
    },
  },

  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  }

}
</script>

<style>

</style>