import axios from "axios";
import store from "@/store";
import requestSender from "@/api/base/requestSender";
import companyConfiguration from "@/plugins/companyConfiguration";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,

  // withCredentials: true,
  headers: {
    // 'selected-user': localStorage.userId ? localStorage.userId : 1,
    // 'X-CSRF-Token': document.getElementById('_csrf_token').innerHTML,
    // accept: 'application/json',
    // 'Content-Type': 'application/json',
    // 'X-Requested-With': 'XMLHttpRequest'
  }

})

axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('jwt');
  config.headers.Authorization = 'Bearer ' + token;

  return config;
});


const refreshURL = 'user/refresh-token';
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });

  failedQueue = [];
};
axiosInstance.interceptors.response.use(
  response => response,
  async err => {
    if( !err.config ){ // Cancelled request
      return Promise.reject({cancelled: true, message: err.message});
    }

    const originalRequest = err.config;

    if (err.response.status === 401) {
      if( !companyConfiguration.configs.auth.isRefreshEnabledForMethod(localStorage.getItem('last_login_method')) ){
        localStorage.setItem('redirected_from', window.location.href);
        window.location.href = '/login';
      }
    }

    if (err.response.status === 401 && !originalRequest._retry) {
      if( err.response.config.url === refreshURL){
        localStorage.setItem('redirected_from', window.location.href);
        window.location.href = '/login';
      }
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({resolve, reject});
        })
          .then(token => {
            return axiosInstance(originalRequest);
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        requestSender('post', refreshURL, {
            refresh_token: localStorage.getItem('refresh_token')
          })
          .then((data) => {
            localStorage.setItem('jwt', data.token);
            localStorage.setItem('refresh_token', data.refresh_token);

            processQueue(null, data.token);
            resolve(axiosInstance(originalRequest));
          })
          .catch(err => {
            processQueue(err, null);
            reject(err);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(err);
  }
);


export default axiosInstance