<template>
  <el-dialog
    :title="element.id ? 'Редактирование цели' : 'Создание цели'"
    :visible.sync="show"
    :width="$companyConfiguration.libraries.getElementEditDialogWidth() + '%'"
    top="2vh"
    :close-on-click-modal="false"
  >
        <!--
          TODO: move this form to the separate component. because we copy it in mass action
          src/componentsCompany/global/massActions/actionTemplates/card/CardAddElementAction.vue
        -->
    <el-form
      size="small"
      ref="editForm"
      label-position="top"
      :model="element"
      :rules="elementRules"
      @submit.native.prevent="saveElement"
    >
      <el-form-item :label="$companyConfiguration.i18n.t('library', 'element_edit_form_name')" prop="name">
        <el-input
          :maxlength="$companyConfiguration.settings.getElementsNameMaxLength()"
          :show-word-limit="!!$companyConfiguration.settings.getElementsNameMaxLength()"
          v-model="element.name"
          ref="elementToFocus"
        >
        </el-input>
      </el-form-item>
      <el-form-item :label="$companyConfiguration.i18n.t('library', 'element_edit_form_desc')" prop="desc">
        <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 6}" v-model="element.desc"></el-input>
      </el-form-item>

      <el-form-item
        v-if="Object.keys($companyConfiguration.scales.getScales()).length > 1"
        :label="$companyConfiguration.i18n.t('library', 'element_edit_form_scale_label', 'Шкала')"
        prop="scale_type"
      >
        <el-select
          v-model="element.scale_type"
          :placeholder="$companyConfiguration.i18n.t('library', 'element_edit_form_scale_select_placeholder', 'Выберите шкалу')"
          @change="scaleTypeChanged"
        >
          <el-option
            v-for="(scale, scaleType) in $companyConfiguration.scales.getScalesForLibraryType(libraryType)"
            :key="scaleType"
            :label="scale.showName"
            :value="scaleType"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <component
      ref="elementScale"
      :is="scaleEditTemplate"
      :data="element.scale"
      :is-clone="isClone"
      :scale-name="element.scale_type"
      :scale-config="scaleEditConfig"
    ></component>

    <div
      v-if="$companyConfiguration.libraries.getElementChangePropagationMode() === $constants.library.elementChangePropagationModes.MODE_NOT_FINISHED_CARDS"
      class="mode-danger"
    >
      Обратите внимание, что редактирование показателя
      не повлияет на показатели в уже завершенных картах
    </div>

    <div slot="footer" class="dialog-footer">

      <el-popconfirm
        v-if="$canAndRulesPass(element, $permissions.LIBRARY.ELEMENT_DELETE)"
        style="float:left"
        v-show="element.id"
        confirm-button-text='Да'
        cancel-button-text='нет'
        icon="el-icon-question"
        title="Вы уверены, что хотите удалить?"
        @confirm="preDeleteElement"
      >
        <el-button
          slot="reference"
          size="small"
          plain
          icon="el-icon-delete"
          :loading="loading"
        >
          Удалить
        </el-button>
      </el-popconfirm>
      <el-button size="small" @click="close">Отмена</el-button>
      <el-button
        type="primary"
        size="small"
        :loading="loading"
        @click="saveElement"
      > Сохранить
      </el-button>
    </div>

  </el-dialog>
</template>

<script>



export default {
  name: "library-element-edit-dialog",
  components: {},

  props: {
    libraryType: { type: String },
    currentFolder: {},
  },

  watch: {
    'element.scale_type': function() {
      this.scale = {};
      this.scaleEditTemplate = this.$companyConfiguration.scales.getScaleTemplate(this.element.scale_type, 'libraryEditForm');
      this.scaleEditConfig = this.$companyConfiguration.scales.getScaleConfig(this.element.scale_type);
    }
  },
  computed: {},
  data() {
    return {
      show: false,
      loading: false,
      isClone: false,

      scaleEditTemplate: null,
      scaleEditConfig: null,

      element: {
        id: null,
        folder_id: null,
        name: null,
        desc: null,
        scale_type: null,
        scale_id: null,
        related_card_elements_count: 0,
        scale: {},
      },

      elementRules: {
        name: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
        scale_type: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'change'}],
      },

    }
  },
  mounted() {

  },
  methods: {
    async open(element,isClone=false) {
      this.isClone = isClone;
      if( this.$refs.editForm ){
        this.$refs.editForm.resetFields();
      }

      this.element = Object.assign({}, element);

      if( isClone ){
        this.element.id = null;
        this.element.scale_id = null;
      }

      // autofill scale_type if only one in system
      if( Object.keys(this.$companyConfiguration.scales.getScales()).length === 1 ){
        await this.$nextTick(); // to make watch detect changes
        this.element.scale_type = Object.keys(this.$companyConfiguration.scales.getScales())[0];
      }

      this.show = true;
      this.$nextTick(() => this.$refs.elementToFocus.focus());
    },
    close() {
      this.show = false;
    },

    scaleTypeChanged(){
      this.element.scale = {};
    },
    preDeleteElement() {
      if( !this.element.related_card_elements_count ){
        this.deleteElement();
        return;
      }

      const message = this.$companyConfiguration.i18n.t(
        'library',
        'delete_element_used_notification',
        'Данный показатель используется в $related_card_elements_count элементах карт. Вы действительно хотите удалить его? Будут удалены все элементы в картах',
        {'related_card_elements_count': this.element.related_card_elements_count}
      );
      this.$confirm(message, 'Внимание', {
        confirmButtonText: 'Да, удалить',
        cancelButtonText: 'нет',
        type: 'warning'
      }).then(() => {
        this.deleteElement();
      });
    },
    deleteElement(){
      this.loading = true;
      this.$api.library.deleteElement({
        id: this.element.id,
      })
        .then(data => {
          this.$notify.success({
            title: 'Удалено',
            message: 'Элемент успешно удален'
          });
        })
        .finally(()=>{
          this.loading = false;
          this.close();
          this.$emit('element-saved');
        })
    },

    saveElement() {
      this.$refs.editForm.validate().then(()=>{
        this.$refs.elementScale.$refs.editForm.validate().then(()=>{
          this.loading = true;
          this.$api.library.saveElement({
            id: this.element.id,
            folder_id: this.element.folder_id ? this.element.folder_id : this.currentFolder.id,
            name: this.element.name,
            desc: this.element.desc,
            scale_type: this.element.scale_type,
            scale_id: this.element.scale_id,
            scale_data: this.$refs.elementScale.scale,
          })
            .then(data => {
              this.$notify.success({
                title: 'Сохранено',
                message: 'Элемент успешно сохранен'
              });
              this.element.scale = {};
            })
            .finally(()=>{
              this.loading = false;
              this.close();
              this.$emit('element-saved');
            })
        })
      })
    },
  }
}
</script>

<style>
.mode-danger{
    color: red;
    font-style: italic;
}
</style>