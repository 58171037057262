<template>
  <div class="total-block" v-if="isDataLoaded">
    <el-table
      class="card-summary-table card-section-elements-table"
      header-row-class-name="card-section-elements-table-header"
      style="width: 100%; margin-bottom: 25px"
      :data="totalEvaluate"
    >
      <el-table-column :label="titleName"></el-table-column>
      <el-table-column :label="evaluationHeader" width="450">
        <template>
          <total-evaluate-select-column
            permission="card.total_evaluate_final_efficiency_update"
            :entity-evaluations="entityEvaluations"
            :evaluate-key="evaluateKey"
            :row="card"
          ></total-evaluate-select-column>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div v-else>Loading...</div>
</template>

<script>
import { mapGetters } from "vuex";
import TotalEvaluateSelectColumn from "@/componentsCompany/smz/tableColumnTemplates/TotalEvaluateSelectColumn";

export default {
  name: "total-evaluate-entity",
  components: {
    TotalEvaluateSelectColumn
  },
  computed: {
    ...mapGetters('card', {
      card: 'card'
    }),
    isDataLoaded() {
      return this.card && this.card.info;
    },
    totalEvaluate() {
      if (this.isDataLoaded) {
        const evaluateValue = this.card.info[this.evaluateKey];
        return [{evaluate: evaluateValue}];
      }
      return [];
    },
    evaluationHeader() {
      if (this.evaluationName) {
        return this.evaluationName;
      }

      return this.isDataLoaded ? this.$companyConfiguration.settings.getEnum('evaluation_headers')[this.entityEvaluateKey] : '';
    }
  },
  props: {
    permission: {
      type: String,
      required: true,
    },
    titleName: {
      type: String,
      required: true,
    },
    entityEvaluateKey: {
      type: Number,
      required: true,
    },
    entityEvaluations: {
      type: Array,
      required: true,
    },
    evaluateKey: {
      type: String,
      required: true,
    },
    evaluationName: {
      type: String,
      required: false,
    },
  },
  methods: {}
}
</script>

<style scoped>
  .total-block {
    margin-top: 30px;
  }
</style>
