<template>
  <div>
    <el-row type="flex" class="row-bg" justify="space-between" style="margin-bottom:10px">
      <el-col :span="4" style="font-weight: bold;padding-top:5px">
        {{ $companyConfiguration.i18n.t('library', 'elements_header', 'Цели') }}
      </el-col>
      <el-col :span="12">
        <el-input
          v-model="search"
          prefix-icon="fas fa-search"
          placeholder="Поиск по названию целей"
          size="mini"
          @input=""
          show-word-limit
          :maxlength="100"
          v-debounce:500="getElements"
        >
          <el-checkbox
            v-model="searchEverywhere"
            slot="suffix" style="padding-top:5px;"
            @change="getElements"
          >
            Везде
          </el-checkbox>
          <el-checkbox
            v-model="showFoldersInSearch"
            slot="suffix"
            style="padding-top:5px;"
            @change="getElements"
          >
            Показывать путь
          </el-checkbox>
        </el-input>
      </el-col>
      <el-col :span="8" align="right" v-if="!modeSelect">
        <el-button
          v-if="$canAndRulesPass(currentFolder, $permissions.LIBRARY.ELEMENT_CREATE)"
          style="margin-left:30px"
          type="primary"
          @click="$refs.editDialog.open({scale_type:null})"
          size="mini"
          icon="fas fa-plus"
          :disabled="!currentFolder || searchEverywhere"
        >
          {{ $companyConfiguration.i18n.t('library', 'add_element_button') }}
        </el-button>
      </el-col>
    </el-row>





    <el-table
      class="library-elements-table"
      :show-header="!!elements.length"
      style="width: 100%"
      :border="$companyConfiguration.libraries.getElementsTableBorder()"
      size="mini"
      :data="elements"
      :empty-text="$companyConfiguration.i18n.t('library', 'elements_empty_text', 'В группе нет целей')"
      v-loading="loading"
      :row-key="(row) => row.id"
      @selection-change="$emit('selection-changed', $event)"
      @sort-change="sortChanged($event, getElements)"
    >
      <el-table-column type="selection" reserve-selection width="55" v-if="modeSelect"></el-table-column>

      <component
        v-for="(columnConfig, index) in columnsConfigs"
        v-if="columnConfig.getVif(thisComponent)"
        :is="columnConfig.manualColumnTemplate ? columnConfig.template : baseTableColumn"
        :key="index"
        :column-config="columnConfig"
        :sortable="setIsSortable(columnConfig.prop)"
      >
      </component>

      <el-table-column align="center" width="120" v-if="!modeSelect" >
        <template v-slot="scope">
          <el-tooltip
            v-if="$canAndRulesPass(currentFolder, $permissions.LIBRARY.ELEMENT_CREATE)"
            content="Клонировать"
            placement="top"
            :open-delay="500"
          >
            <el-button
              class="table-action-button"
              type="default"
              round
              size="mini"
              icon="far fa-copy"
              @click="$refs.editDialog.open(scope.row, true)"
            >
            </el-button>
          </el-tooltip>
          <el-tooltip
            v-if="$canAndRulesPass(scope.row, $permissions.LIBRARY.ELEMENT_UPDATE)"
            content="Редактировать"
            placement="top"
            :open-delay="500"
          >
            <el-button
              class="table-action-button"
              type="default"
              round
              size="mini"
              icon="fas fa-pen"
              @click="$refs.editDialog.open(scope.row)"
            >
            </el-button>
          </el-tooltip>
          <el-tooltip
            v-if="$canAndRulesPass(scope.row, $permissions.LIBRARY.ELEMENT_SEE_HISTORY)"
            content="История изменений"
            placement="top"
            :open-delay="500"
          >
            <history-modal
              style="display: inline-block; margin-left: 10px; text-align: left"
              title="История изменений показателя"
              width="70%"
              :method="$api.library.getElementHistory"
              :params="{  id: scope.row.id }"
            >
              <el-button
                slot="reference"
                class="table-action-button"
                type="default"
                round
                size="mini"
                icon="fas fa-history"
              ></el-button>
            </history-modal>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>







    <library-element-edit-dialog
      ref="editDialog"
      :library-type="libraryType"
      :current-folder="currentFolder"
      @element-saved="getElements"
    >
    </library-element-edit-dialog>

  </div>
</template>

<script>

import LibraryElementEditDialog from "@/components/library/LibraryElementEditDialog";
import LibraryElementScaleConfigTableColumn
  from "@/components/tableColumnTemplates/library/LibraryElementScaleConfigTableColumn";
import {mapGetters} from "vuex";
import HistoryModal from "@/components/HistoryModal.vue";
import {sorter} from "@/mixins/sorter";

export default {
  name: "library-elements",
  components: {HistoryModal, LibraryElementScaleConfigTableColumn, LibraryElementEditDialog},
  mixins: [sorter],

  props: {
    libraryType: { type: String },
    currentFolder: {},
    modeSelect: { type: Boolean, default: false },
	  fromCard: {type: Number},
  },

  watch: {
    currentFolder: function(newVal) {
      if( !newVal ){
        return;
      }
      this.getElements();
    }
  },

  computed: {
    baseTableColumn() {
      return () => import(`@/components/tableColumnTemplates/BaseTableColumn`);
    },
  },

  data() {
    return {
      thisComponent: this,
      columnsConfigs: [],

      loading: false,


      search: null,
      searchEverywhere: false,
      showFoldersInSearch: false,

      elements: [],
    }
  },

  beforeMount() {
    this.columnsConfigs = this.$companyConfiguration.helpers.getTableColumnsArrayFromConfig(
      this.$companyConfiguration.libraries.getElementsTableColumns(this.libraryType)
    );
  },


  methods: {
    getElements(){
      this.loading = true;
      this.$api.library.searchElements({
        search: this.search,
	      library_type: this.libraryType,
	      select_mode: this.modeSelect ? 1 : null,
	      from_card: this.fromCard ? this.fromCard : null,
        everywhere: this.searchEverywhere ? 1 : null,
        folder_id: this.currentFolder ? this.currentFolder.id : null,
        expand: [
          'scale',
          'related_card_elements_count',
          (this.modeSelect ? null : 'permissions'),
          (this.showFoldersInSearch ? 'path' : null),
        ].join(),

        [this.sorter.param]: this.sorter.sort,
      })
        .then(({data, sorter}) => {
          this.elements = data.elements;
          this.sorterFill(sorter);
        })
        .finally(()=>{
          this.loading = false;
        })
    },
  }
}
</script>

<style>

</style>