<template>
  <div
    v-if="card && card.id && this.card.status !== this.$constants.card.statuses.STATUS_COMPLETED"
    style="margin-top: 20px; float: right"
  >
    <el-button
      v-if="$can(card, 'card.disagree') && $rulesPass(card, 'card.disagree')"
      type="danger"
      icon="fas fa-undo"
      :loading="loading"
      @click="disagreeNegotiation"
    >
      Отправить на доработку
    </el-button>


    <el-button
      v-if="$can(card, 'card.agree')"
      :disabled="!$rulesPass(card, 'card.agree')"
      type="success"
      icon="fas fa-check"
      :loading="loading"
      @click="agreeNegotiation"
    >
      <span v-if="currentNegotiation && currentNegotiation.type === 'self'">Отправить на согласование</span>
      <span v-else>Согласовать</span>
    </el-button>
  </div>
</template>

<script>


import {mapGetters} from "vuex";

export default {
  name: "agree-buttons",
  components: {},

  props: {},

  computed: {
    ...mapGetters('card', {
      card: 'card',
    }),
    ...mapGetters('card/stages', {
      currentStage: 'currentStage',
      currentNegotiation: 'currentNegotiation',
    }),
    ...mapGetters('card/errors', {
      cardWarnings: 'warnings',
    }),
  },
  data() {
    return {
      loading: false,
    }
  },
  mounted() {},
  methods: {
    agreeNegotiation(){
      if( this.cardWarnings.length ){
        let message = 'В карте присутствуют следующие предупреждения:<br><br>';
        message += this.cardWarnings.join('<br>');
        message += '<br><br>Вы уверены, что хотите продолжить?';
        this.$confirm(message, 'Внимание', {
          confirmButtonText: 'Да',
          cancelButtonText: 'нет',
          dangerouslyUseHTMLString: true,
          type: 'warning'
        }).then(() => {
          this.sendAgreeNegotiation();
        })
      } else {
        this.sendAgreeNegotiation();
      }
    },

    sendAgreeNegotiation(){
      this.loading = true;
      this.$api.card.agreeCard({
        card_id: this.card.id
      })
        .then((data) => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Этап успешно сохранен'
          });
        })
        .finally(() => {
          this.loading = false;
          this.$store.dispatch('card/loadCard');
        })
    },

    disagreeNegotiation(){
      this.loading = true;
      this.$api.card.disagreeCard({
        card_id: this.card.id
      })
        .then((data) => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Этап успешно сохранен'
          });
        })
        .finally(() => {
          this.loading = false;
          this.$store.dispatch('card/loadCard');
        })
    },


  }
}
</script>

<style>

</style>