<template>
  <el-form
    ref="cardEditForm"
    size="small"
    label-position="top"
    :model="card"
    :rules="cardRules"
  >

    <period-selector
      :year.sync="card.year"
      :period.sync="card.period"
      :period-index.sync="card.period_index"
    ></period-selector>


    <el-form-item label="Этапы">
      <el-row
        v-for="stageType in $companyConfiguration.cardStages.getTypes()"
        v-if="!$companyConfiguration.cardStages.ifStageHideOnCardCreate(stageType)"
        :key="stageType"
        class="card-edit-dialog-stage"
      >
        <el-checkbox
          v-model="stages[stageType].enabled"
          border
        >
          {{ $companyConfiguration.cardStages.getStageShowName(stageType) }}
        </el-checkbox>
        <div
          v-show="stages[stageType].enabled"
          class="card-edit-dialog-stage-content"
        >
          <stage-item
            :stage="stages[stageType]"
            :show-stage-name="false"
            :hide-stage-dates="$companyConfiguration.cardStages.getStageConfig(stageType).hideDates"
            @negotiation-deleted="negotiationDeleted(stages[stageType], $event)"
          >
          </stage-item>
        </div>

      </el-row>
    </el-form-item>


    <el-form-item :label="$companyConfiguration.i18n.t('cards', 'sections_block_name', 'Секции')">
      <div
        v-if="!Object.keys($companyConfiguration.card.getAvailableSectionsByPeriod(card.period)).length"
        class="el-cascader__empty-text"
      >
        {{ $companyConfiguration.i18n.t('cards', 'no_sections_for_period_text', 'Для данного периода секции не предусмотрены') }}
      </div>

      <el-checkbox-group v-model="sections" class="sections-group">
        <el-checkbox
          v-for="(sectionConfig, sectionType) in $companyConfiguration.card.getAvailableSectionsByPeriod(card.period)"
          :key="sectionType"
          :label="sectionType"
          :checked="!!sectionConfig.selected"
          border
        >
          {{ $companyConfiguration.cardSections.getSectionShowName(sectionType) }}
        </el-checkbox>
      </el-checkbox-group>
    </el-form-item>

    <component
      :is="cardInfoBlockTemplate"
      :info.sync="card.info"
      :card="card"
    ></component>
  </el-form>
</template>

<script>
import {Container, Draggable} from 'vue-dndrop';
import StageItem from "@/components/cards/CardStagesBlock/StageItem";
import UsersTable from "@/components/user/UsersTable";
import CardCreateTemplates from "@/components/cards/CardCreateTemplates.vue";
import {mapGetters} from "vuex";
import PeriodSelector from "@/components/PeriodSelector.vue";

export default {
  name: "card-edit-dialog",
  components: {PeriodSelector, CardCreateTemplates, UsersTable, StageItem, Container, Draggable},

  props: {},

  computed: {
    ...mapGetters(['user']),
    cardInfoBlockTemplate() {
      return this.$companyConfiguration.card.getCardCreateInfoTemplate();
    },
  },
  watch: {
    'card.period': function () {
      this.sections = [];
    }
  },
  data() {
    return {
      card: {
        year: new Date().getFullYear().toString(),
        period: null,
        period_index: null,
        info: {},
      },
      cardRules: {
        year: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'change'}],
        period: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'change'}],
        period_index: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'change'}],
      },

      sections: [],
      stages: {},
    }
  },
  beforeMount() {
    for (const [stageType, stageConfig] of Object.entries(this.$companyConfiguration.cardStages.getTypesConfig())) {

      let defaultNegotiations = [];
      for (const [index, negotiationType] of Object.entries(this.$companyConfiguration.cardStageNegotiations.getNegotiationTypesForStage(stageType))) {
        defaultNegotiations.push({
          type: negotiationType,
          permissions: {
            [this.$permissions.CARD_STAGE_NEGOTIATION.MOVE]: {can: true, rulesPass: true, errors: []},
            [this.$permissions.CARD_STAGE_NEGOTIATION.DELETE]: {can: true, rulesPass: true, errors: []},
          }
        });
      }

      this.$set(this.stages, stageType, {
        enabled: !!stageConfig.selected,
        type: stageType,
        date_start: stageConfig.date_start,
        date_end: stageConfig.date_end,
        order: stageConfig.order,
        permissions: {
          [this.$permissions.CARD_STAGE_NEGOTIATION.CREATE]: {can: true, rulesPass: true, errors: []},
          [this.$permissions.CARD_STAGE.SET_DATE]: {can: true, rulesPass: true, errors: []},
        },
        negotiations: defaultNegotiations,
      })
    }
  },
  mounted() {
  },
  methods: {
    validate() {
      return this.$refs.cardEditForm.validate();
    },

    negotiationDeleted(stage, {negotiationIndex, negotiation}) {
      stage.negotiations.splice(negotiationIndex, 1);
    },


    async setSettingsFromTemplate(template) {
      const cardInfo = template.settings.card.info; // to prevent data loss

      this.card = template.settings.card;
      this.stages = template.settings.stages;

      await this.$nextTick(); // card.period watch resets sections
      this.sections = template.settings.sections;
      this.card.info = cardInfo;
    },

  }
}
</script>

<style scoped lang="scss">

.card-edit-dialog-stage:not(:last-child) {
  margin-bottom: 20px;
}

.card-edit-dialog-stage-content {
  padding-left: 20px;
  padding-top: 20px;

  &-dates {
    margin: 10px 0;

    &-datepicker {
      width: 140px;
    }
  }
}

.sections-group {
  label.el-checkbox {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 5px;
    display: block;
  }
}


</style>