<template>
  <el-dialog
    :title="manageUsersModal.usersTypeChangingState === 'users' ? 'Пользователи группы' : 'Соавторы группы'"
    :visible.sync="manageUsersModal.show"
    width="70%"
    :close-on-click-modal="false"
  >
    <el-row>
      <users-table
        :loading="manageUsersModal.loading"
        :users.sync="manageUsersModal.users"
        search-field
        add-button-type="primary"
        @search="manageUsersModalSearchChanged"
        @page-changed="manageUsersModalPageChanged"
        @user-deleted="manageUsersModalUserDeleted"
        @users-added="manageUsersModalUsersAdded"
      ></users-table>

      <paginator
        :paginator.sync="paginator"
        @page-changed="manageUsersModalUsersSearch"
      ></paginator>
    </el-row>

    <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="manageUsersModal.show = false">Закрыть</el-button>
      </span>
  </el-dialog>
</template>

<script>

import {off, on} from "element-ui/src/utils/dom";
import {paginator} from "@/mixins/paginator";
import Paginator from "@/components/Paginator";
import CardsComponent from "@/components/cards/CardsComponent";
import UsersTable from "@/components/user/UsersTable.vue";

export default {
  name: "group-users-dialog",
  components: {UsersTable, CardsComponent, Paginator},
  mixins: [paginator],
  props: {
    // manageUsersModal: {type: Object}
  },

  data() {
    return {
      // show: false,
      loading: false,

      manageUsersModal: {
        usersTypeChangingState: null, // Какое модальное сейчас открыто
        show: false,
        loading: false,
        groupId: null,
        search: null,
        page: 1,
        users: [],
        paginator: null,
      },
    }
  },

  watch: {},
  methods: {
    manageUsersModalShow(groupId, state) {
      this.manageUsersModal.usersTypeChangingState = state;
      this.manageUsersModal.groupId = groupId;
      this.manageUsersModal.search = null;
      this.manageUsersModal.show = true;
      this.manageUsersModal.paginator = null;
      this.manageUsersModal.users = [];
      // this.paginator.page =1;
      this.paginatorReset()
      this.manageUsersModalUsersSearch();
    },
    manageUsersModalUsersSearch() {
      this.manageUsersModal.loading = true;
      const method = this.manageUsersModal.usersTypeChangingState === 'users' ? this.$api.user.search : this.$api.group.getCoauthors;
      method({
        group_ids: [this.manageUsersModal.groupId],
        search: this.manageUsersModal.search,

        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      })
        .then(({data, paginator}) => {
          this.manageUsersModal.users = data.users;
          this.paginatorFill(paginator);
        })
        .finally(() => {
          this.manageUsersModal.loading = false;
        })
    },
    manageUsersModalSearchChanged(search) {
      this.manageUsersModal.search = search;
      this.manageUsersModalUsersSearch();
    },
    manageUsersModalPageChanged(page) {
      this.manageUsersModal.page = page;
      this.manageUsersModalUsersSearch();
    },

    manageUsersModalUsersAdded(users) {
      let ids = users.map(user => user.id);
      this.manageUsersModal.loading = true;
      this.$api.group.addUsersToGroup({
        type: this.manageUsersModal.usersTypeChangingState,
        group_id: this.manageUsersModal.groupId,
        user_ids: ids
      })
        .then(data => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Пользователи успешно добавлены'
          });
        })
        .finally(() => {
          this.manageUsersModalUsersSearch();
        })
    },
    manageUsersModalUserDeleted(user) {
      this.manageUsersModal.loading = true;
      this.$api.group.deleteUsersFromGroup({
        type: this.manageUsersModal.usersTypeChangingState,
        group_id: this.manageUsersModal.groupId,
        user_ids: [user.id]
      })
        .then(data => {
          this.$notify.success({
            title: 'Удалено',
            message: 'Пользователь успешно удален из группы'
          });
        })
        .finally(() => {
          this.manageUsersModal.loading = false;
        })
    },
  },


}
</script>

<style>

</style>