<template>
  <div v-if="$canAndRulesPass(user, 'resources_evaluation.see_in_menu')">
    <page-heading
      heading="Оценка кадрового потенциала"
    >
      <span slot="help"></span>
      <div slot="right">
        <el-popconfirm
          v-if="$canAndRulesPass(user, 'resources_evaluation.create_matrix')"
          width="400"
          confirm-button-text='Да'
          cancel-button-text='нет'
          icon="el-icon-question"
          title="Вы действительно хотите создать матрицы? Матрицы будут созданы для пользователей, у которых в подчинении есть хотя бы 1 пользователь и ещё нет матрицы на текущий год"
          @confirm="createMatrix"
        >
          <el-button
            slot="reference"
            size="small"
            :loading="loading"
          >
            Создать матрицы
          </el-button>
        </el-popconfirm>
      </div>
    </page-heading>
    <el-card>
      <div>

        <div style="display: flex; justify-content: space-between; gap: 20px; flex-flow: wrap;">
          <search-input
            placeholder="Поиск"
            style="width: 200px"
            v-model="filters.search"
            @change="filtersChanged"
          >
          </search-input>
          <user-unique-prop-values-select
            placeholder="Локация"
            style="width: 150px"
            v-model="filters.locations"
            prop="location"
            multiple
            @selected="filtersChanged"
          ></user-unique-prop-values-select>
          <user-unique-prop-values-select
            placeholder="Подразделение"
            style="width: 170px"
            v-model="filters.subdivisions"
            prop="subdivision"
            multiple
            @selected="filtersChanged"
          ></user-unique-prop-values-select>
          <user-unique-prop-values-select
            placeholder="Цех"
            style="width: 100px"
            v-model="filters.workshops"
            prop="workshop"
            multiple
            @selected="filtersChanged"
          ></user-unique-prop-values-select>
          <user-select
            placeholder="ФИО НР"
            style="width: 200px"
            v-model="filters.direct_chief_user_id"
            :multiple="false"
            @selected="filtersChanged"
          ></user-select>
          <user-unique-prop-values-select
            v-model="filters.bands"
            placeholder="Бенд"
            style="width: 100px"
            prop="band"
            multiple
            @selected="filtersChanged"
          ></user-unique-prop-values-select>
          <cards-year-select
            v-model="filters.year"
            style="width: 120px"
            auto-set-current-year
            :multiple="false"
            :clearable="false"
            @change="filtersChanged"
          ></cards-year-select>
          <cards-status-select
            v-model="filters.statuses"
            placeholder="Статус карты целей"
            style="width: 190px"
            @change="filtersChanged"
          ></cards-status-select>
          <el-select
            v-model="availableCards"
            style="width: 190px"
            :multiple="false"
            clearable
            placeholder="Доступные оценки"
          >
            <el-option label="Мои оценки" value="my"></el-option>
            <el-option label="Оценки карт на моем шаге" value="my_turn"></el-option>
            <el-option label="Оценки непосредственных подчиненных НР" value="immediate_subordinates_tree_direct"></el-option>
            <el-option label="Оценки всех подчиненных НР" value="all_subordinates_tree_direct"></el-option>
          </el-select>
          <el-checkbox
            v-model="filters.with_matrix"
            label="Показать матрицы"
            border
            :true-label="1"
            :false-label="null"
            @change="filtersChanged"
          ></el-checkbox>
        </div>


        <el-table
          ref="cardsTable"
          style="width: 100%"
          :data="cards"
          empty-text="Нет карт"
          v-loading="loading"
          :row-key="(row) => row.id"
          @sort-change="sortChanged($event, loadCards)"
        >
          <el-table-column fixed label="ФИО Сотрудника/Руководителя" v-slot="scope" width="160" prop="user.last_name" sortable="custom">
            <router-link
              v-if="scope.row.matrix && $canAndRulesPass(user, 'resources_evaluation.see_matrix')"
              :to="{ name: 'HumanResourcesMatrix', params: { id: scope.row.matrix.id } }"
            >
              <div>{{ scope.row.user.full_name }}</div>
            </router-link>
            <div v-else>{{ scope.row.user.full_name }}</div>
          </el-table-column>
          <el-table-column label="Локация" v-slot="scope" width="150" prop="user.location" sortable="custom">
            {{ scope.row.user.location }}
          </el-table-column>
          <el-table-column label="Подразделение" v-slot="scope" width="200" prop="user.subdivision" sortable="custom">
            {{ scope.row.user.subdivision }}
          </el-table-column>
          <el-table-column label="Цех" v-slot="scope" width="150" prop="user.workshop" sortable="custom">
            {{ scope.row.user.workshop }}
          </el-table-column>
          <el-table-column label="Бенд" v-slot="scope" width="90" prop="user.band" sortable="custom">
            {{ scope.row.user.band }}
          </el-table-column>
          <el-table-column label="ФИО непосредственного руководителя" v-slot="scope" width="230" prop="user.directChief.full_name" sortable="custom">
            {{ scope.row.user.directChief ? scope.row.user.directChief.full_name : '' }}
          </el-table-column>
          <el-table-column label="Год карты целей" v-slot="scope" width="100" prop="year" sortable="custom">
            <router-link-to-card-column-template
                :row="scope.row"
                :value="scope.row.year.toString()"
            />
          </el-table-column>
          <el-table-column label="Статус карты целей" v-slot="scope" width="150" prop="status" sortable="custom">
            <current-stage-column-template
              :row="scope.row"
            />
          </el-table-column>
          <el-table-column label="Итоговая оценка эффективности деятельности" v-slot="scope" width="300" prop="info.evaluate_final_efficiency_id" sortable="custom">
            <total-evaluate-select-column
              permission="card.total_evaluate_final_efficiency_update"
              :entity-evaluations="evaluations[evaluationTypes['TYPE_FINAL_EFFICIENCY']]"
              :evaluate-key="totalEvaluations['TOTAL_EVALUATE_FINAL_EFFICIENCY']"
              :row="scope.row"
              clearable
            ></total-evaluate-select-column>
          </el-table-column>
          <el-table-column label="Потенциал" v-slot="scope" width="300" prop="info.evaluate_potential_id" sortable="custom">
            <total-evaluate-select-column
              v-if="scope.row.info.hasOwnProperty(totalEvaluations['TOTAL_EVALUATE_POTENTIAL'])"
              permission="card.subordinate_evaluations_update"
              :entity-evaluations="evaluations[evaluationTypes['TYPE_POTENTIAL']]"
              :evaluate-key="totalEvaluations['TOTAL_EVALUATE_POTENTIAL']"
              :row="scope.row"
              clearable
            ></total-evaluate-select-column>
          </el-table-column>
          <el-table-column label="Риск увольнения" v-slot="scope" width="300" prop="info.evaluate_risk_dismissal_id" sortable="custom">
            <total-evaluate-select-column
              v-if="scope.row.info.hasOwnProperty(totalEvaluations['TOTAL_EVALUATE_RISK_DISMISSAL'])"
              permission="card.subordinate_evaluations_update"
              :entity-evaluations="evaluations[evaluationTypes['TYPE_RISK_DISMISSAL']]"
              :evaluate-key="totalEvaluations['TOTAL_EVALUATE_RISK_DISMISSAL']"
              :row="scope.row"
              clearable
            ></total-evaluate-select-column>
          </el-table-column>
          <el-table-column label="Влияние на бизнес" v-slot="scope" width="300" prop="info.evaluate_impact_on_business_id" sortable="custom">
            <total-evaluate-select-column
              v-if="scope.row.info.hasOwnProperty(totalEvaluations['TOTAL_EVALUATE_IMPACT_ON_BUSINESS'])"
              permission="card.subordinate_evaluations_update"
              :entity-evaluations="evaluations[evaluationTypes['TYPE_IMPACT_BUSINESS']]"
              :evaluate-key="totalEvaluations['TOTAL_EVALUATE_IMPACT_ON_BUSINESS']"
              :row="scope.row"
              clearable
            ></total-evaluate-select-column>
          </el-table-column>
          <el-table-column v-slot="scope" width="50">
            <el-popconfirm
              v-if="scope.row.matrix && $canAndRulesPass(user, 'resources_evaluation.delete_matrix')"
              confirm-button-text='Да'
              cancel-button-text='нет'
              icon="el-icon-question"
              title="Вы уверены, что хотите удалить матрицу?"
              @confirm="deleteMatrix(scope.row.matrix.id)"
            >
              <el-button
                slot="reference"
                class="table-action-button"
                size="mini"
                round
                icon="far fa-trash-alt"
              ></el-button>
            </el-popconfirm>
          </el-table-column>
        </el-table>
        <paginator
          :paginator.sync="paginator"
          use-router-query-param
          @page-changed="loadCards"
        ></paginator>
      </div>
    </el-card>
  </div>
</template>
<script>
import requestSender from "@/api/base/requestSender";
import CurrentStageColumnTemplate from "@/components/tableColumnTemplates/cards/StatusColumnTemplate";
import RouterLinkToCardColumnTemplate from "@/componentsCompany/smz/tableColumnTemplates/cards/RouterLinkToCardColumnTemplate";
import PageHeading from "@/components/PageHeading";
import Paginator from "@/components/Paginator";
import {paginator} from "@/mixins/paginator";
import CardsComponent from "@/components/cards/CardsComponent";
import {mapGetters} from "vuex";
import TotalEvaluateEntity from "@/componentsCompany/smz/TotalEvaluateEntity";
import TotalEvaluateSelectColumn from "@/componentsCompany/smz/tableColumnTemplates/TotalEvaluateSelectColumn";
import {sorter} from "@/mixins/sorter";
import UserUniquePropValuesSelect from "@/components/filters/users/UserUniquePropValuesSelect.vue";
import SearchInput from "@/components/filters/SearchInput.vue";
import UserSelect from "@/components/filters/users/UserSelect.vue";
import CardsYearSelect from "@/components/filters/cards/CardsYearSelect.vue";
import CardsStatusSelect from "@/components/filters/cards/CardsStatusSelect.vue";

export default {
  name: 'Cards',
  components: {
    CardsStatusSelect,
    CardsYearSelect,
    UserSelect, SearchInput, UserUniquePropValuesSelect,
    TotalEvaluateEntity,
    CardsComponent,
    Paginator,
    PageHeading,
    CurrentStageColumnTemplate,
    TotalEvaluateSelectColumn,
    RouterLinkToCardColumnTemplate,
  },
  mixins: [paginator, sorter],
  computed: {
    ...mapGetters(['user']),
  },
  created() {
    this.getEvaluations();
  },
  watch: {
    availableCards: function () {
      this.filters.my = this.availableCards === 'my' ? 1 : null;
      this.filters.my_turn = this.availableCards === 'my_turn' ? 1 : null;

      this.filters.subordinates = this.availableCards;

      this.filtersChanged();
    },
  },
  data() {
    return {
      loading: false,
      cards: [],
      evaluationTypes: this.$companyConfiguration.settings.getEnum('evaluation_types'),
      totalEvaluations: this.$companyConfiguration.settings.getEnum('evaluation_total_evaluations'),

      availableCards: [],
      filters: {
        search: null,
        locations: [],
        subdivisions: [],
        workshops: [],
        bands: [],
        statuses: [],
        year: null,
        direct_chief_user_id: null,
        my: null,
        my_turn: null,
        subordinates: null,
        with_matrix: null,
      },
      evaluations: {},
    }
  },
  mounted() {
    this.loadCards();
  },
  methods: {
    loadCards() {
      this.loading = true;

      requestSender('get', 'human-resources-evaluation/search', {
        ...this.filters,

        [this.sorter.param]: this.sorter.sort,
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
        expand: this.$companyConfiguration.card.getCardsPageSearchExpand().join(),
      })
        .then(({data, paginator, sorter}) => {
          this.cards = data.cards;
          this.paginatorFill(paginator);
          this.sorterFill(sorter);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getEvaluations() {
      requestSender('get', 'evaluation/search', {})
        .then(({evaluations}) => {
          this.evaluations = evaluations;
        })
    },
    createMatrix() {
      this.loading = true;

      requestSender('post', 'human-resources-evaluation/create-matrix', {})
        .then(() => {
        })
        .finally(() => {
          this.loadCards();
        })
    },

    filtersChanged(){
      this.loadCards();
    },

    deleteMatrix(matrixId) {
      this.loading = true;

      requestSender('post', 'human-resources-evaluation/delete-matrix', {
        matrix_id: matrixId,
      })
        .then(() => {})
        .finally(() => {
          this.loadCards();
        });
    },
  }
}
</script>

<style scoped lang="scss"></style>