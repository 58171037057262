<template>

  <div>

    <page-heading
      :heading="pageTitle"
    >
      <span slot="help"></span>
      <div slot="right">
        <el-button
          v-if="$canAndRulesPass(user, $permissions.GROUP.CREATE)"
          type="primary"
          size="small"
          icon="fas fa-plus"
          @click="groupEditModalShow({})"
        >
          Создать группу
        </el-button>
      </div>
    </page-heading>

    <el-card>

      <el-table
        v-loading="loading"
        size="mini"
        empty-text="Нет групп"
        :data="groups"
      >
        <el-table-column prop="name" label="Название"></el-table-column>
        <el-table-column width="700" align="right">
          <template v-slot="scope">
            <el-button
              v-if="$canAndRulesPass(scope.row, $permissions.GROUP.UPDATE)"
              type="primary"
              plain
              size="mini"
              icon="far fa-user"
              @click="manageUsersModalShow(scope.row.id, 'coauthors')"
            >
              Управление соавторами
            </el-button>
            <el-button
              v-if="$canAndRulesPass(scope.row, $permissions.GROUP.UPDATE)"
              type="primary"
              plain
              size="mini"
              icon="far fa-user"
              @click="manageUsersModalShow(scope.row.id, 'users')"
            >
              Управление пользователями
            </el-button>
            <el-button
              v-if="$canAndRulesPass(scope.row, $permissions.GROUP.UPDATE)"
              class="table-action-button"
              size="mini"
              round
              icon="fas fa-pen"
              @click="groupEditModalShow(scope.row)"
            >
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <paginator
        :paginator.sync="paginator"
        @page-changed="getUserGroups"
      ></paginator>

    </el-card>


    <el-dialog
      :title="groupEditModal.group.id ? 'Редактирование группы' : 'Создание группы'"
      :visible.sync="groupEditModal.show"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-row style="">
        <el-form
          ref="groupForm"
          size="small"
          label-position="top"
          :model="groupEditModal.group"
          :rules="groupEditModal.rules"
        >
          <el-form-item label="Наименование" prop="name">
            <el-input v-model="groupEditModal.group.name"></el-input>
          </el-form-item>
        </el-form>


      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-popconfirm
          v-if="$canAndRulesPass(groupEditModal.group, $permissions.GROUP.DELETE)"
          style="float:left"
          v-show="groupEditModal.group.id"
          confirm-button-text='Да'
          cancel-button-text='нет'
          icon="el-icon-question"
          title="Вы уверены, что хотите удалить группу?"
          @confirm="deleteGroup"
        >
          <el-button
            slot="reference"
            size="small"
            plain
            icon="far fa-trash-alt"
            :loading="loading"
          >
            Удалить
          </el-button>
        </el-popconfirm>
        <el-button size="small" @click="groupEditModal.show = false">Отмена</el-button>
        <el-button size="small" type="primary" :loading="loading" @click="saveGroup">Сохранить</el-button>
      </div>
    </el-dialog>



    <group-users-dialog
      ref="groupUsersDialog"
    ></group-users-dialog>

  </div>

</template>

<script>
import PageHeading from "../components/PageHeading";
import UsersTable from "../components/user/UsersTable";
import Paginator from "@/components/Paginator";
import {mapGetters} from "vuex";
import {paginator} from "@/mixins/paginator";
import GroupUsersDialog from "@/components/groups/GroupUsersDialog.vue";

export default {
  name: 'Groups',
  components: {GroupUsersDialog, Paginator, UsersTable, PageHeading,},
  mixins: [paginator],

  computed: {
    ...mapGetters(['user']),
    pageTitle(){
      return document.title;
    },
  },
  mounted() {
    this.getUserGroups();
  },

  data() {
    return {
      loading: false,
      groups: [],

      groupEditModal: {
        show: false,
        group: {
          id: null,
          name: null,
        },
        rules: {
          name: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
        },
      },



    }
  },
  methods: {
    getUserGroups() {
      this.loading = true;
      this.$api.group.search({
        expand: 'permissions',

        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      })
        .then(({data, paginator}) => {
          this.groups = data.groups;
          this.paginatorFill(paginator);
        })
        .finally(() => {
          this.loading = false;
        })
    },

    groupEditModalShow(group) {
      if (this.$refs.groupForm) {
        this.$refs.groupForm.resetFields();
      }

      this.groupEditModal.group.id = group.id;
      this.groupEditModal.group.name = group.name;
      this.groupEditModal.group.permissions = group.permissions;
      this.groupEditModal.show = true;
    },

    deleteGroup() {
      this.loading = true;
      this.$api.group.delete({
        id: this.groupEditModal.group.id
      })
        .then((data) => {
          this.$notify.success({
            title: 'Удалено',
            message: 'Группа успешно удалена'
          });
        })
        .finally(() => {
          this.getUserGroups();
          this.groupEditModal.show = false;
        })
    },

    saveGroup() {
      this.loading = true;
      this.$api.group.save(this.groupEditModal.group)
        .then((data) => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Группа успешно сохранена'
          });
        })
        .finally(() => {
          this.getUserGroups();
          this.groupEditModal.show = false;
        })
    },

    manageUsersModalShow(groupId, state) {
      this.$refs.groupUsersDialog.manageUsersModalShow(groupId, state);
    },



  }
}

</script>

<style>

</style>