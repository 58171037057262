<template>
  <div
    v-if="$canAndRulesPass(user, $permissions.RBAC.LIST)"
  >
    <page-heading
      :heading="pageTitle"
    >
      <span slot="help"></span>
      <span slot="right"></span>
    </page-heading>

    <el-card
      style="margin-top: 20px;"
    >
      <rbac-table></rbac-table>
    </el-card>

  </div>
</template>

<script>
import PageHeading from "@/components/PageHeading";
import RbacTable from "@/components/settings/rbac/RbacTable";
import {mapGetters} from "vuex";
import ScalesSettings from "@/componentsCompany/tmk/scalesSettings/ScalesSettings.vue";
import ChiefsEditComponent from "@/components/user/ChiefsEditComponent.vue";

export default {
  name: 'Rbac',
  components: { RbacTable, PageHeading},

  computed: {
    ...mapGetters(['user']),
    pageTitle(){
      return document.title;
    },
  },
  data() {
    return {

    }
  },

  methods: {

  }
}

</script>


<style lang="scss">
</style>
