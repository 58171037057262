<template>
  <el-card>
    <div class="human-resource-matrix-header" v-loading="loadingInfo">
      <el-row
        type="flex"
        class="human-resource-matrix-header-element"
      >
        <div class="human-resource-matrix-header-element-icon">
          <i class="fas fa-user" />
        </div>
        <div class="human-resource-matrix-header-element-text">
          <div class="human-resource-matrix-header-element-text-header el-tag--plain el-tag--info">
            Год
          </div>
          <div class="human-resource-matrix-header-element-text-body">
            <div>{{matrixInfo.year}}</div>
          </div>
        </div>
      </el-row>
      <el-row
        type="flex"
        class="human-resource-matrix-header-element"
      >
        <div class="human-resource-matrix-header-element-icon">
          <i class="fas fa-user" />
        </div>
        <div class="human-resource-matrix-header-element-text">
          <div class="human-resource-matrix-header-element-text-header el-tag--plain el-tag--info">
            Непосредственный руководитель
          </div>
          <div class="human-resource-matrix-header-element-text-body">
            <div>{{matrixInfo.user ? matrixInfo.user.full_name : ''}}</div>
          </div>
        </div>
      </el-row>
      <el-row
        type="flex"
        class="human-resource-matrix-header-element"
      >
        <div class="human-resource-matrix-header-element-icon">
          <i class="fas fa-user" />
        </div>
        <div class="human-resource-matrix-header-element-text">
          <div class="human-resource-matrix-header-element-text-header el-tag--plain el-tag--info">
            Подразделение
          </div>
          <div class="human-resource-matrix-header-element-text-body">
            <div>{{matrixInfo.user ? matrixInfo.user.subdivision : ''}}</div>
          </div>
        </div>
      </el-row>
      <el-row
        type="flex"
        class="human-resource-matrix-header-element"
      >
        <div class="human-resource-matrix-header-element-icon">
          <i class="fas fa-user" />
        </div>
        <div class="human-resource-matrix-header-element-text">
          <div class="human-resource-matrix-header-element-text-header el-tag--plain el-tag--info">
            Бенд
          </div>
          <div class="human-resource-matrix-header-element-text-body">
            <div>{{matrixInfo.user ? matrixInfo.user.band : ''}}</div>
          </div>
        </div>
      </el-row>
      <el-row
        type="flex"
        class="human-resource-matrix-header-element"
      >
        <div class="human-resource-matrix-header-element-icon">
          <i class="fas fa-user" />
        </div>
        <div class="human-resource-matrix-header-element-text">
          <div class="human-resource-matrix-header-element-text-header el-tag--plain el-tag--info">
            Вышестоящий руководитель
          </div>
          <div class="human-resource-matrix-header-element-text-body">
            <div>{{matrixInfo.user && matrixInfo.user.directChief ? matrixInfo.user.directChief.full_name : ''}}</div>
          </div>
        </div>
      </el-row>

    </div>


  </el-card>
</template>
<script>
import {mapGetters} from "vuex";
import requestSender from "@/api/base/requestSender";

export default {
  name: 'HumanResourcesMatrixHeader',
  components: {},

  computed: {
    ...mapGetters(['user']),

  },


  data() {
    return {
      loadingInfo: false,

      matrixInfo: {},
    }
  },
  mounted() {
    this.getMatrixInfo();
  },
  methods: {
    getMatrixInfo(){
      this.loadingInfo = true;

      requestSender('get', 'human-resources-evaluation/get-matrix-info', {
        matrix_id: parseInt(this.$route.params.id),
        expand: 'user, user.directChief',
      })
        .then(data => {
          this.matrixInfo = data.matrix_info;
        })
        .finally(() => {
          this.loadingInfo = false;
        });
    },
  }
}
</script>

<style lang="scss">

.human-resource-matrix-header {
  display: flex;
  //gap: 3rem;
  justify-content: space-between;

  &-element {
    .human-resource-matrix-header-element-icon {
      margin-right:6px
    }

    i {
      padding: 12px;
      background-color: #F5F7FA;
      color: #8F9298;
      border-radius: 0;
    }
  }
}

</style>