<template>
  <div>
    {{ index + 1 }}.
    <router-link
      :to="{ name: 'Card', params: { id: card.id } }"
      target="_blank"
    >
      <div style="display: inline-block">{{ card.user.full_name }}</div>
    </router-link>

    <i
      style="margin-left: 10px"
      class="fas fa-cog"
      :class="{
        'human-resources-matrix-card-assessment-not-started': card.status < 51,
        'human-resources-matrix-card-assessment-started': card.status >= 51,
        'human-resources-matrix-card-assessment-finished': card.status === 200,
      }"
    ></i>


  </div>
</template>

<script>

import {downloader} from "@/mixins/downloader";


export default {
  name: "HumanResourcesMatrixTableUser",
  mixins: [downloader],
  props: {
    card: {type: Object, required: true},
    index: {type: Number, required: false},
  },


  data() {
    return {

    }
  },

  methods: {

  },
}
</script>

<style lang="scss">
@import "@/assets/css/project-variables";
.human-resources-matrix-card-assessment-not-started {
  color: #4b4b4b !important;
}
.human-resources-matrix-card-assessment-started {
  color: #ff9100 !important;
}
.human-resources-matrix-card-assessment-finished {
  color: #20be00 !important;
}
</style>