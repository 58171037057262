<template>
  <div style="">
    <el-table
      ref="rbacTable"
      :key="tableKey"
      :data="filteredPermissions"
      border
      style="width: 100%"
      :height="'78vh'"
      v-loading="loading"
      row-key="name"
    >
<!--      default-expand-all-->
      <el-table-column fixed prop="description" label="Название" min-width="300">
        <template slot="header"  slot-scope="scope">
          <div align="center">
            <div class="el-row--flex">
              <el-input
                v-model="permissionsSearch"
                placeholder="Фильтр по названию полномочия"
                size="medium"
                clearable
                prefix-icon="el-icon-search"
              ></el-input>
              <el-button
                v-if="$canAndRulesPass(user, $permissions.RBAC.ROLE_CREATE)"
                style="margin-left: 20px"
                type="primary"
                @click="manageEditModalShow({})"
                size="mini"
                icon="fas fa-plus"
              >
                Создать роль
              </el-button>
            </div>
          </div>
        </template>
        <template v-slot="scope">
          {{ scope.row.description }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        v-for="role in roles"
        min-width="140"
        :key="role.id"
      >
        <template v-slot:header>
          <div align="center">
            <div>
              {{ role.description }}
              <el-button
                v-if="$canAndRulesPass(user, $permissions.RBAC.ROLE_UPDATE)"
                class="table-action-button"
                style="margin-left: 5px"
                size="mini"
                round
                icon="fas fa-pen"
                @click="manageEditModalShow(role)"
              >
              </el-button>
            </div>
            <div>
              <el-button
                v-if="$canAndRulesPass(user, $permissions.RBAC.MANAGE_USERS)"
                style="margin-top: 5px"
                type="primary"
                plain
                size="mini"
                icon="far fa-user"
                @click="manageUsersModalShow(role)"
              >
                Управление
              </el-button>
            </div>
          </div>
        </template>
        <template v-slot="scope">
          <div style="position: relative;">
            <el-switch
              v-if="scope.row.roles"
              :disabled="!$canAndRulesPass(user, $permissions.RBAC.SET_PERMISSION)"
              v-model="scope.row.roles[role.name]"
              @change="permissionChanged(scope.row, role)"
            >
            </el-switch>
              <el-button
                v-if="scope.row.roles"
                style="position: absolute; top:0; right:0;opacity: 0.3; color: gray"
                type="text"
                size="mini"
                icon="fas fa-history"
                @click="manageHistoryModalShow(role.name, scope.row.name)"
              >
              </el-button>
          </div>

        </template>
      </el-table-column>
    </el-table>

    <history-modal
      ref="historyModalDialog"
      title="История изменений"
      width="50%"
      hide-type-full-name
      hide-field-full-name
      :method="$api.rbac.getHistory"
    >
    </history-modal>


    <rbac-role-users-dialog
      ref="roleUsersDialog"
    ></rbac-role-users-dialog>

    <rbac-role-edit-dialog
      ref="roleEditDialog"
      @role-updated="getPermissionsWithRoles"
    ></rbac-role-edit-dialog>

  </div>
</template>

<script>

import RbacRoleUsersDialog from "@/components/settings/rbac/RbacRoleUsersDialog";
import RbacRoleEditDialog from "@/components/settings/rbac/RbacRoleEditDialog";
import {mapGetters} from "vuex";
import HistoryModal from "@/components/HistoryModal.vue";

export default {
  name: 'rbac-table',
  components: {HistoryModal, RbacRoleEditDialog, RbacRoleUsersDialog},

  data() {
    return {
      loading: false,

      roles: [],
      permissions: [],

      permissionsSearch: '',
      tableKey: 1,
    }
  },
  computed: {
    ...mapGetters(['user']),
    filteredPermissions() {
      if (!this.permissionsSearch) {
        return this.groupedPermissions;
      }

      return this.permissions.filter(permission => {
        return permission.description.toLowerCase().indexOf(this.permissionsSearch.toLowerCase()) > -1
      })
    },

    groupedPermissions() {
      let groups = {};
      this.permissions.forEach(permission => {
        const permissionGroup = permission.description.split('.')[0];
        if (!groups[permissionGroup]) {
          groups[permissionGroup] = [];
        }
        groups[permissionGroup].push(permission);
      });

      let groupedPermissions = [];
      for (const [key, value] of Object.entries(groups)) {
        groupedPermissions.push({
          name: key,
          description: value[0].description.split('.')[0], // Берем имя из первого пермишена до точки
          children: value,
        })
      }
      return groupedPermissions;
    }
  },

  mounted() {
    this.getPermissionsWithRoles();
  },
  methods: {
    getPermissionsWithRoles() {
      this.roles = [];
      this.permissions = [];

      this.loading = true;
      this.$api.rbac.getPermissionsWithRoles()
        .then(data => {
          this.permissions = data.permissions;
          this.roles = data.roles;
          this.$nextTick(() => {
            this.tableKey++;
          });
        })
        .finally(() => {
          this.loading = false;
        })
    },

    permissionChanged(permission, role) {
      this.loading = true;
      this.$api.rbac.setRolePermission({
        role_name: role.name,
        permission_name: permission.name,
        value: permission.roles[role.name],
      })
        .then(data => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Правило успешно сохранено'
          });
        })
        .finally(() => {
          this.loading = false;
        })
    },


    manageUsersModalShow(role) {
      this.$refs.roleUsersDialog.open(role);
    },
    manageEditModalShow(role) {
      this.$refs.roleEditDialog.open(role);
    },

    manageHistoryModalShow(role_name, permission_name){
      this.$refs.historyModalDialog.showHistory(null, {  role_name: role_name, permission_name: permission_name });
    },


  }
}

</script>

<style>

</style>