import axiosInstance from "./axiosInstance";
import router from '@/router'
import {Notification} from 'element-ui';
import store from "@/store";

const requestSender = (method, url, payload={}, options = {}) => {
  method = method.toLowerCase();
  if (method === 'get') {
    payload = {...options, params: payload};
  }
  return new Promise((resolve, reject) => {
    axiosInstance[method](url, payload, options)
      .then((response) => {

        if (options && options.responseType && options.responseType === 'blob') {
          resolve({
            data: response.data,
            headers: response.headers,
          });
        }

        if (response.data._paginator || response.data._sorter) {
          resolve({
            data: response.data,
            paginator: response.data._paginator,
            sorter: response.data._sorter
          });
        } else {
          resolve(response.data);
        }
      })
      .catch(e => {
        if( e.cancelled ){
          return;
        }
        processError(e.response);
        reject(e.response);
      })
  });

}

async function processError(response) {
  const status = response ? response.status : false;
  // const originalRequest = response.config;

  // console.log(status);
  // console.log(originalRequest);


  if (status === 404) {
    if (router.currentRoute.name === '404') {
      return;
    }
    await router.push({name: '404'});
    return;
  }

  if (status === 401) {
    // if (originalRequest.url.includes("user/refresh-token")) {
    //   await store.dispatch('logoutUser');
    //   window.location.href = '/login';
    //   return;
    // } else if (!originalRequest._retry) {
    //   originalRequest._retry = true;
    //
    //   let newTokens = await requestSender('post', 'auth/login-pass/refresh', {
    //     refresh_token: localStorage.getItem('refresh_token')
    //   })
    //
    //   await localStorage.setItem('jwt', newTokens.token);
    //   await localStorage.setItem('refresh_token', newTokens.refresh_token);
    //
    //   return axiosInstance(originalRequest)
    //     .catch(e => { processError(e.response); })
    // }

    // window.location.href = '/login';
    // return;
  }

  let message = 'Что-то пошло не так';
  if (response && response.data) {
    if( response.data.errors && response.data.errors.length ){
      message = parseErrorsForMessage(response.data.errors);
    } else {
      if (response.data.message) {
        message = response.data.message;
      }
    }
  }
  Notification.error({
    title: 'Ошибка',
    message: message,
    dangerouslyUseHTMLString: true
  });
}

function parseErrorsForMessage(errors) {
  let message = '';
  if (errors instanceof Array) {
    message = '';
    errors.forEach(error => {
      if (error instanceof Object) {
        message += '<div>' + error.error + '</div>';
      } else {
        if (!error) return;
        message += '<div>' + error + '</div>';
      }
    })
  } else if (errors instanceof Object) {
    message = '';
    for (const [key, value] of Object.entries(errors)) {
      errors[key].forEach(error => {
        if (!error) return;
        message += '<div>' + error + '</div>';
      })
    }
  }
  if (!message.length) {
    message = 'Что-то пошло не так';
  }
  return message;
}


export default requestSender