export default {
    "default_negotiations": [
        "self",
        "direct_chief",
        "functional_chief"
    ],
    "stage_negotiations": [],
    "on_disagree_go_to_self_negotiation": true,
    "types": {
        "self": {
            "showName": "Сотрудник"
        },
        "direct_chief": {
            "showName": "Непосредственный руководитель"
        },
        "functional_chief": {
            "showName": "Функциональный руководитель"
        },
        "other": {
            "showName": "Ручной"
        }
    }
}