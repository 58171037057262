<template>
  <div>
    <el-dialog
      title="Удаленные карты"
      destroy-on-close
      :visible.sync="show"
      :width="width"
      top="2vh"
      :close-on-click-modal="false"
    >
      <el-row>
        <cards-component
          filter-deleted
          hide-last-column
          selectable
          @selection-changed="selectionChanged"
        ></cards-component>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="show = false">Закрыть</el-button>
        <el-button
          type="primary"
          size="small"
          :disabled="!selectedCards.length"
          @click="restoreSelected"
        >
          Восстановить отмеченные
        </el-button>
      </div>
    </el-dialog>

    <span ref="wrapper">
      <slot name="reference"></slot>
    </span>
  </div>
</template>

<script>

import {off, on} from "element-ui/src/utils/dom";
import {paginator} from "@/mixins/paginator";
import Paginator from "@/components/Paginator";
import CardsComponent from "@/components/cards/CardsComponent";

export default {
  name: "deleted-cards-modal",
  components: {CardsComponent, Paginator},
  mixins: [paginator],
  props: {
    method: Function,
    params: Object,
    width: {
      type: String,
      default: '95%'
    },
    showModelFullName: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      show: false,
      loading: false,

      cards: [],
      selectedCards: [],
    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showDeleted);
    }
  },
  watch: {},
  methods: {
    showDeleted() {
      this.show = true;
    },

    selectionChanged(newSelection) {
      this.selectedCards = newSelection;
    },

    restoreSelected() {
      this.loading = true;
      let cardIds = this.selectedCards.map(card => card.id);
      this.$api.card.restoreCards({
        card_ids: cardIds,
      })
        .then(data => {
          this.$notify.success({
            title: 'Восстановлено',
            message: this.$companyConfiguration.i18n.t('cards', 'card_restored_message'),
          });
        })
        .finally(()=>{
          this.loading = false;
          this.show = false;
          this.$emit('cards-restored');
        })
    },
  },

  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showDeleted);
    }
  }

}
</script>

<style>

</style>