export default {
    "types": {
        "1": {
            "showName": "Создана"
        },
        "11": {
            "showName": "Постановка целей Сотрудником"
        },
        "15": {
            "showName": "Постановка целей у Руководителя"
        },
        "19": {
            "showName": "Постановка целей завершена"
        },
        "31": {
            "showName": "Промежуточные результаты у Сотрудника"
        },
        "35": {
            "showName": "Промежуточные результаты у Руководителя"
        },
        "39": {
            "showName": "Промежуточные результаты завершены"
        },
        "51": {
            "showName": "Оценка в конце года Сотрудником"
        },
        "55": {
            "showName": "Оценка в конце года Руководителем"
        },
        "200": {
            "showName": "Оценка в конце года завершена"
        }
    }
}