<template>
  <div>
    <el-dialog
      title="Шкала распределения оценок"
      :visible.sync="show"
      width="40%"
      :close-on-click-modal="false"
      :show-close="false"
      :close-on-press-escape="false"
    >

      <el-form
        class="distribution-form"
        ref="distributionForm"
        size="mini"
        :model="model"
        :rules="rules"
      >
      <el-table
        v-loading="loading"
        size="mini"
        empty-text="Нет данных"
        :data="evaluations"
      >
        <el-table-column prop="name" label="Оценка"></el-table-column>
        <el-table-column label="Распределение, %">
          <template v-slot="scope">
          <el-form-item :prop="scope.row.id.toString()">

            <click-to-edit
              v-model="scope.row.calibrationDistribution.distribution"
              :number-format="{}"
              placeholder="Введите распределение"
              @input="distributionChanged(scope.row.id, $event)"
            ></click-to-edit>

            </el-form-item>
          </template>
        </el-table-column>
      </el-table>

      </el-form>


      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeForm">Закрыть</el-button>
      </span>
    </el-dialog>

    <span ref="wrapper">
      <slot name="reference"></slot>
    </span>
  </div>
</template>

<script>

import {off, on} from "element-ui/src/utils/dom";
import requestSender from "@/api/base/requestSender";
import ClickToEdit from "@/components/ClickToEdit.vue";


export default {
  name: "calibration-evaluation-to-distribution-modal",
  components: {ClickToEdit},
  props: {},

  data() {
    return {
      loading: false,
      show: false,
      evaluations: [],

      model:{
        12: null,
        13: null,
        14: null,
        15: null,
      },
      rules:{
        12:  [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
        13:  [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
        14:  [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
        15:  [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
      }
    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  },
  watch: {},
  methods: {
    showModal(){
      this.show = true;
      this.loadEvaluations();
    },

    loadEvaluations() {
      this.loading = true;

      requestSender('get', 'calibration/get-evaluations', {
        expand: ['calibrationDistribution'].join()
      })
        .then(data => {
          this.evaluations = data.evaluations;
          data.evaluations.forEach(evaluation => {
            this.model[evaluation.id] = evaluation.calibrationDistribution.distribution;
          })
        })
        .finally(() => {
          this.loading = false;
        })
    },

    distributionChanged(evaluationId, newValue){
      this.model[evaluationId] = newValue;
      if( !newValue ){
        return;
      }

      requestSender('post', 'calibration/set-evaluation-distribution', {
        evaluation_id: evaluationId,
        distribution: newValue,
      })
        .then(() => {
          this.loadEvaluations();

          this.$notify.success({
            title: 'Сохранено',
            message: 'Распределение успешно сохранено'
          });
        })
        .finally(() => {
          this.loading = false;
        })
    },

    async closeForm(){
      let success = await this.$refs.distributionForm.validate();
      if( success ){
        this.show = false;
        this.$emit('on-close')
      }
    }
  },

  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  }

}
</script>

<style>
.distribution-form .el-form-item:not(.is-error) {
  margin-bottom: 0 !important;
}
</style>