import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        search(payload){
            return requestSender('get', 'user/search', payload);
        },
        searchUniqueUsersPropValues(payload){
            return requestSender('get', 'user/search-unique-users-prop-values', payload);
        },
        getAuthUser(payload){
            return requestSender('get', 'user/auth-user-data', payload);
        },
        selectionByExcel(payload){
            return requestSender('post', 'user/selection-by-excel', payload);
        },

    }
}