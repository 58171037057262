import Api from "@/api/base";
import Vue from "vue";

import cardStagesModule from './card/stages'
import cardSectionsModule from './card/sections'
import cardErrorsModule from './card/errors'

export default {
    namespaced: true,

    state: {
        loading: false,
        card: {
            id: null,
            bonus:null,
        },
    },

    getters: {
        card(state){
            return state.card
        },
        loading(state) {
            return state.loading
        },
    },
    actions: {
        loadCard(ctx, payload) {
            ctx.commit('setLoading', true);
            ctx.commit('clearCard');
            Api.card.getCard({
                id: payload?.id ? payload.id : ctx.state.card.id,
                expand: 'info, permissions, user, user.meta, user.chiefs, chiefs_card_id'
            })
                .then(data => {
                    ctx.commit('setLoading', false);
                    ctx.commit('updateCard', data.card);
                    // ctx.dispatch('card/errors/loadErrors', null, {root: true});

                })
                .finally(() => {
                    ctx.commit('setLoading', false);
                })
        },

        loadCardData(ctx, payload) {
            Api.card.getCard({
                id: payload?.id ? payload.id : ctx.state.card.id,
                expand: 'info, permissions'
            })
                .then(data => {
                    ctx.commit('updateCardData', data.card);

                })
                .finally(() => {
                })
        },

        unloadCard(ctx){
            ctx.commit('updateCard', {});
            ctx.dispatch('card/stages/unloadStages', null, {root: true});
            ctx.dispatch('card/sections/unloadSections', null, {root: true});
            ctx.dispatch('card/errors/unloadErrors', null, {root: true});
        }
    },

    mutations: {
        setLoading(state, isLoading){
            state.loading = isLoading;
        },

        clearCard(state){
            state.card.permissions = {};
            state.card.sections = [];
        },

        updateCard(state, card){
            state.card = card;
        },
        updateCardData(state, card){
            state.card.permissions = card.permissions;
            state.card.info = card.info;
            state.card.bonus = card.bonus;
        },
    },

    modules: {
        stages: cardStagesModule,
        sections: cardSectionsModule,
        errors: cardErrorsModule,
    }
}