<template>
  <div
    v-if="$canAndRulesPass(user, permissionSeeUnits)"
    v-loading="loading"
  >
    <div
      v-for="unit in units"
      class="library-scales-unit-row"
    >
      <el-input
        v-model="unit.symbol"
        :disabled="!$canAndRulesPass(user, permissionEditUnit)"
        style="width: 100%"
        size="mini"
        show-word-limit
        maxlength="100"
        @change="saveUnit(unit)"
      ></el-input>

      <el-popconfirm
        v-if="unit.id && $canAndRulesPass(user, permissionDeleteUnit)"
        style="margin-left: 10px; margin-top:2px"
        confirm-button-text='Да'
        cancel-button-text='нет'
        icon="el-icon-question"
        title="Вы уверены, что хотите удалить единицу измерения?"
        @confirm="deleteUnit(unit)"
      >
        <el-button
          slot="reference"
          class="table-action-button"
          type="default"
          round
          size="mini"
          icon="far fa-trash-alt"
          :loading="loading"
        >
        </el-button>

      </el-popconfirm>
    </div>


    <el-button
      v-if="$canAndRulesPass(user, permissionAddUnit)"
      size="small"
      type="text"
      icon="fas fa-plus"
      @click="addUnit"
    >
      Добавить единицу измерения
    </el-button>
  </div>
</template>

<script>


import {mapGetters} from "vuex";

export default {
  name: "library-scales-units-form",
  components: {},
  props: {
    permissionSeeUnits: {type: String, default: 'scales.edit_units'},
    permissionAddUnit: {type: String, default: 'scales.edit_units'},
    permissionEditUnit: {type: String, default: 'scales.edit_units'},
    permissionDeleteUnit: {type: String, default: 'scales.edit_units'},
  },
  computed: {
    ...mapGetters(['user']),
  },
  data() {
    return {
      loading: false,
      units: [],
    }
  },
  mounted() {
    this.loadUnits();
  },
  watch: {},
  methods: {
    loadUnits() {
      this.loading = true;
      this.$api.library.getScalesUnits()
        .then(data => {
          this.units = data.scales_units;
        })
        .finally(() => {
          this.loading = false;
        })
    },

    addUnit() {
      this.units.push({
        id: null,
        symbol: null,
      });
    },

    saveUnit(unit) {
      this.loading = true;
      this.$api.library.saveScalesUnit(unit)
        .then(data => {
          unit.id = data.scales_unit.id;
          this.$notify.success({
            title: 'Сохранено',
            message: 'Единица измерения успешно сохранена'
          });
        })
        .finally(() => {
          this.loading = false;
        })
    },

    deleteUnit(unit) {
      this.loading = true;
      this.$api.library.deleteScalesUnit({
        id: unit.id,
      })
        .then(data => {
          this.loadUnits();
          this.$notify.success({
            title: 'Удалено',
            message: 'Единица измерения успешно удалена'
          });
        })
    },
  },

}
</script>

<style>
.library-scales-unit-row {
  display: flex;
  margin-bottom: 5px;
}
</style>