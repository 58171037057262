<template>
  <div style="display: inline-block">
    <el-dialog
      title="Выбор элементов"
      destroy-on-close
      :visible.sync="show"
      :width="width"
      top="2vh"
      :close-on-click-modal="false"
      @close="close"
    >
      <el-row v-loading="loading">
        <card-section-selectable
          v-for="section in sections"
          :key="section.id"
          :section="section"
          @selection-changed="selectionChanged(section.type, $event)"
        >
        </card-section-selectable>

      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="close">Закрыть</el-button>
        <el-button
          type="primary"
          size="small"
          :disabled="!selectedElementIds.length"
          @click="addSelectedElements"
        >
          Добавить отмеченные
        </el-button>
      </div>
    </el-dialog>

    <div ref="wrapper">
      <slot name="reference"></slot>
    </div>
  </div>
</template>

<script>

import {off, on} from "element-ui/src/utils/dom";
import {paginator} from "@/mixins/paginator";
import Paginator from "@/components/Paginator";
import CardsComponent from "@/components/cards/CardsComponent";
import CardSectionSelectable from "@/components/cards/CardElementsBlock/CardSectionSelectable.vue";
import Vue from "vue";
import {mapGetters} from "vuex";

export default {
  name: "clone-elements-from-card-modal",
  components: {CardSectionSelectable},
  mixins: [],
  props: {
    width: {
      type: String,
      default: '75%'
    },
    cardId:{ type:Number }
  },

  data() {
    return {
      show: false,
      loading: false,

      sections: [],
      selectedElements: {},
    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showCard);
    }
  },
  watch: {},
  computed: {
    ...mapGetters('card', {
      card:'card'
    }),
    selectedElementIds(){
      let ids = [];
      for (const [key, value] of Object.entries(this.selectedElements)) {
        ids = [...ids, ...value.map(element => element.id)];
      }
      return ids;
    }
  },
  methods: {
    showCard() {
      this.show = true;
      this.loading = true;
      this.$api.card.getCard({
        id: this.cardId,
        expand: 'sections'
      })
        .then(data => {
          this.sections = data.card.sections;
        })
        .finally(()=>{
          this.loading = false;
        })
    },

    close(){
      this.selectedElements = {};
      this.sections = [];

      this.show = false;
    },

    selectionChanged(sectionType, newSelection) {
      Vue.set(this.selectedElements, sectionType, newSelection);
    },


    addSelectedElements(){
      this.loading = true;
      this.$api.card.cloneElementsToCard({
        to_card_id: this.card.id,
        element_ids: this.selectedElementIds,
      })
        .then(data => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Элементы успешно добавлены'
          });
        })
        .finally(()=>{
          this.$emit('elements-cloned');
          this.loading = false;
          this.close();
        })
    },

  },

  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showCard);
    }
  }

}
</script>

<style>

</style>