<template>
    <highcharts ref="chart" :options="chartOptions" style="height:250px"></highcharts>
</template>

<script>


import {Chart as highcharts} from "highcharts-vue";

export default {
  name: "calibration-chart",
  components: {highcharts},
  props: {
    cells: {type: Object, required: true},
  },

  watch: {
    cells: function() {

      this.$refs.chart.chart.series[0].setData(
        [
          {x: 1, y: this.cells['B2'], name:this.cells['B1']},
          {x: 2, y: this.cells['C2'], name:this.cells['C1']},
          {x: 3, y: this.cells['D2'], name:this.cells['D1']},
          {x: 4, y: this.cells['E2'], name:this.cells['E1']}
        ],
        false
      );

      this.$refs.chart.chart.series[1].setData(
        [
          {x: 1, y: this.cells['B3'], name:this.cells['B1']},
          {x: 2, y: this.cells['C3'], name:this.cells['C1']},
          {x: 3, y: this.cells['D3'], name:this.cells['D1']},
          {x: 4, y: this.cells['E3'], name:this.cells['E1']}
        ],
        true
      );
      // this.$refs.chart.chart.xAxis.categories = ['a', 'b']
      // this.$refs.chart.chart.series[1].setData(
      //   [this.cells['B3'], this.cells['C3'], this.cells['D3'], this.cells['E3']],
      //   true
      // );
    }
  },

  data() {
    return {
      chartOptions: {
        credits: {enabled: false},
        title: false,

        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle'
        },
        plotOptions: {
          series: {
            lineWidth: 4,
            // dataLabels: {
            //   enabled: true,
            //   format: '{y}%',
            //   style: { fontSize: '20px'},
            // }
          }
        },

        yAxis: {
          title: false,
          max: 100,
          labels: {
            format: '{value}%'
          }
        },
        xAxis: {
          type: 'category',
        },

        series: [
          {
            name: 'Цель, %',
            color:'#6c9bff',
          },
          {
            name: 'Факт, %',
            color: '#ffa44a',
            dataLabels: {
              enabled: true,
              format: '{y}%',
              style: { fontSize: '20px'},
            },
          },
        ],
      }
    }
  },

  methods: {},
}
</script>

<style>
.calibration-cell-highlighted {
  background-color: #fac8c8;
}
</style>