<template>
  <div>
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :title="titleText"
      :visible.sync="show"
      width="70%"
      destroy-on-close
    >
      <el-row>
        <el-row type="flex" justify="space-between" style="margin-bottom: 32px;">
          <el-col :span="11">
            <el-input
              v-model="search"
              prefix-icon="el-icon-search"
              :placeholder="$companyConfiguration.i18n.t('user', 'users_search_input_placeholder', 'Поиск')"
              size="small"
              clearable
              @clear="searchChanged"
              v-debounce:500="searchChanged"
            >
            </el-input>
          </el-col>
          <el-col :span="11">
            <user-group-select
              v-model="groupIds"
              v-if="$canAndRulesPass(user, $permissions.GROUP.LIST)"
              size="small"
              @selected="searchUsers"
            ></user-group-select>
          </el-col>

        </el-row>
        <el-table
          v-loading="loading"
          :class="{'single-select': singleSelect}"
          ref="selectUsersTable"
          size="mini"
          empty-text="Нет пользователей"
          :data="users"
          :row-key="(row) => row.id"
          @current-change="handleCurrentChange"
          @sort-change="sortChanged($event, searchUsers)"
        >
          <el-table-column
            v-if="!singleSelect"
            type="selection"
            reserve-selection
            width="55"
          ></el-table-column>

          <component
            v-for="(columnConfig, index) in columnsConfigs"
            :is="columnConfig.manualColumnTemplate ? columnConfig.template : baseTableColumn"
            :key="index"
            :column-config="columnConfig"
            :sortable="setIsSortable(columnConfig.prop)"
          >
          </component>

        </el-table>
        <paginator
          :paginator.sync="paginator"
          @page-changed="searchUsers"
        ></paginator>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="show = false">Отмена</el-button>
        <el-button
          :disabled="singleSelect || ($refs.selectUsersTable && !$refs.selectUsersTable.selection.length)"
          :loading="loading"
          :type=" singleSelect ? 'text' : 'primary'"
          @click="addSelectedUsers"
          size="small"
          :icon="singleSelect ? '' : 'fas fa-plus'"
        >
          {{ addButtonText }}
        </el-button>
      </div>
    </el-dialog>

    <span ref="wrapper">
      <slot name="reference"></slot>
    </span>
  </div>
</template>

<script>
import {off, on} from "element-ui/src/utils/dom";
import Paginator from "../Paginator";
import {paginator} from "@/mixins/paginator";
import UserGroupSelect from "@/components/filters/users/UserGroupSelect";
import {sorter} from "@/mixins/sorter";
import {mapGetters} from "vuex";

export default {
  name: "add-users-dialog",
  mixins: [paginator, sorter],
  components: {UserGroupSelect, Paginator},

  props: {
    filterRole: {},
    titleText: {type: String, default: 'Добавление пользователей'},
    addButtonText: {type: String, default: 'Добавить отмеченных'},
    singleSelect: { type: Boolean, default: false},
  },

  computed: {
    ...mapGetters(['user']),
    baseTableColumn() {
      return () => import(`@/components/tableColumnTemplates/BaseTableColumn`);
    },
  },
  data() {
    return {
      loading: false,
      show: false,
      columnsConfigs: [],

      search: null,
      groupIds: [],
      roleName: null,
      users: [],
    }
  },
  beforeMount() {
    this.columnsConfigs = this.$companyConfiguration.helpers.getTableColumnsArrayFromConfig(
      this.$companyConfiguration.user.getUsersListColumns()
    );
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  },
  watch: {},
  methods: {
    showModal() {
      this.search = null;
      this.paginator.page = 1;
      this.show = true;
      this.users = [];

      this.searchUsers();
    },
    searchChanged(search) {
      this.paginator.page = 1;
      this.searchUsers();
    },


    searchUsers() {
      this.users = [];
      this.loading = true;
      const filterRole = this.filterRole ? this.filterRole : this.$companyConfiguration.user.getUsersListDefaultRoleFilterInSearch();
      this.$api.user.search({
        roles: filterRole
          ? Array.isArray(filterRole)
            ? filterRole
            : [filterRole]
          : null,
        group_ids: this.groupIds,
        search: this.search,

        [this.sorter.param]: this.sorter.sort,
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      })
        .then(({data, paginator, sorter}) => {
          this.users = data.users;
          this.sorterFill(sorter);
          this.paginatorFill(paginator);
        })
        .finally(() => {
          this.loading = false;
        })
    },

    addSelectedUsers(singleUser) {
      this.show = false;
      this.$emit('users-selected', this.singleSelect ? singleUser : this.$refs.selectUsersTable.selection);
    },

    handleCurrentChange(user) {
      if (!this.singleSelect) {
        return;
      }
      this.addSelectedUsers(user);
    }
  },
  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  }
}
</script>

<style>
.single-select tr:hover{
  cursor: pointer;
}
</style>