<template>
  <div class="card-stages" v-loading="loading">

    <el-card
      v-for="(stage, stageIndex) in stages"
      :key="stageIndex"
      class="stage"
      :shadow="stage.id === card.current_stage_id ? 'always' : 'never'"
    >

      <stage-item
        :stage="stage"
        :active="stage.id === card.current_stage_id"
        :hide-stage-dates="$companyConfiguration.cardStages.getStageConfig(stage.type).hideDates"

        @stage-dates-changed="setStageDates"

        @negotiation-selected="setActiveNegotiation"
        @negotiation-saved="saveNegotiation"

        @negotiation-moved="saveMovedNegotiation"
        @negotiation-deleted="deleteNegotiation"

        @dynamic-stage-started="startDynamicStage"
        @stage-deleted="deleteStage"
      ></stage-item>
    </el-card>
  </div>
</template>

<script>
import {Container, Draggable} from 'vue-dndrop';
import StageItem from "@/components/cards/CardStagesBlock/StageItem";
import {mapGetters} from "vuex";

export default {
  name: "card-stages-block",
  components: {StageItem, Container, Draggable},

  props: {},

  computed: {
    ...mapGetters('card', {
      card: 'card',
    }),
    ...mapGetters('card/stages', {
      loading: 'loading',
      stages: 'stages'
    }),


    stagesWithDynamic: function () {
      // Идея в том, что мы должны заинжектить в середину этапы,
      // которые могут добавляться динамически
      // Например Корректировка целей. Мы можем дать юзеру возможность добавить этот этап
      // в процессе работы. И визуально он будет видеть, что он может добавить этот этап

      let stagesWithDynamic = [...this.stages];

      for (const [stageType, stageConfig] of Object.entries(this.$companyConfiguration.cardStages.getTypesConfig())) {
        if (!stageConfig.canBeAddedDynamically) {
          continue;
        }
        if (stagesWithDynamic.find(s => s.type === stageType)) {
          continue;
        }

        let indexToInsert = stagesWithDynamic.findIndex(s => (s.order + 1) >= stageConfig.order);
        if (indexToInsert === -1) {
          continue;
        }

        stagesWithDynamic.splice(indexToInsert + 1, 0, {
          dynamic: true,
          type: stageType,
          order: indexToInsert + 1,
          card_id: this.card.id,
        })
      }

      return stagesWithDynamic;
    },

  },
  data() {
    return {}
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'card/updateCard') {
        this.$store.dispatch('card/stages/loadStages');
      }
    });
  },
  mounted() {
    this.$store.dispatch('card/stages/loadStages');
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    setStageDates(stage) {
      this.$store.dispatch('card/stages/setStageDates', stage)
        .then(data => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Даты успешно сохранены'
          });
        })
    },

    setActiveNegotiation({negotiation, sendNotify}) {
      this.$store.dispatch('card/stages/setActiveNegotiation', {negotiation, sendNotify})
        .then(data => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Этап успешно сохранен'
          });
          this.$store.dispatch('card/loadCardData');
        })
    },

    saveNegotiation(negotiation) {
      this.$store.dispatch('card/stages/saveNegotiation', negotiation)
        .then(data => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Этап успешно сохранен'
          });
          this.$store.dispatch('card/loadCardData');
        })
    },

    saveMovedNegotiation(movedNegotiation, newPosition) {
      this.$store.dispatch('card/stages/saveMovedNegotiation', {movedNegotiation, newPosition})
        .then(data => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Порядок успешно сохранен'
          });
          this.$store.dispatch('card/loadCardData');
        })
    },

    deleteNegotiation({negotiationIndex, negotiation}) {
      this.$store.dispatch('card/stages/deleteNegotiation', negotiation)
        .then(data => {
          this.$notify.success({
            title: 'Удалено',
            message: 'Согласование успешно удалено'
          });
          this.$store.dispatch('card/loadCardData');
        })
    },

    startDynamicStage(stage) {
      this.$store.dispatch('card/stages/startDynamicStage', stage)
        .then(data => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Этап успешно создан'
          });
        })
    },

    deleteStage(stage) {
      this.$store.dispatch('card/stages/deleteStage', stage)
        .then(data => {
          this.$notify.success({
            title: 'Удалено',
            message: 'Этап успешно удален'
          });
        })
    },
  }
}
</script>


<style scoped lang="scss">
@import "./src/assets/css/project-variables";

.card-stages {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1.3rem;
  justify-content: space-around;

  .stage{
    min-width: 360px;
  }
}

.stage.el-card.is-always-shadow {
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 30%)
}
</style>