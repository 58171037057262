<template>
<!--  Из-за ТМК оставляем старое полномочие setting.scales. Нужно в будущем убрать -->
  <div v-if="$canAndRulesPass(user, 'setting.scales') || $canAndRulesPass(user, $permissions.CONFIGURABLE_SCALES.SEE_IN_MENU)">
<!--    <page-heading-->
<!--      heading="Шкалы"-->
<!--    >-->
<!--      <span slot="help"></span>-->
<!--      <span slot="right"></span>-->
<!--    </page-heading>-->


<!--    <el-card>-->
    <component :is="scalesSettingsTemplate"></component>

<!--      <scales-settings></scales-settings>-->
<!--    </el-card>-->





  </div>
</template>

<script>
import PageHeading from "@/components/PageHeading";
import {mapGetters} from "vuex";
import ScalesSettings from "@/componentsCompany/tmk/scalesSettings/ScalesSettings.vue";

export default {
  name: 'Settings',
  components: {ScalesSettings, PageHeading},

  computed: {
    ...mapGetters(['user']),
    scalesSettingsTemplate() {
      return this.$companyConfiguration.scales.getScalesSettingsTemplate();
    },
  },
  data() {
    return {

    }
  },
  mounted() {},
  methods: {

  }
}

</script>


<style lang="scss">


</style>
