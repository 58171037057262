<template>
  <div>
    <page-heading
      heading="Единицы измерения"
    >
      <span slot="help"></span>
      <span slot="right"></span>
    </page-heading>

    <library-scales-units-form
      permission-see-units="scales.see_units"
    ></library-scales-units-form>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import LibraryScalesUnitsForm from "@/components/library/LibraryScalesUnitsForm.vue";
import PageHeading from "@/components/PageHeading.vue";

export default {
  name: 'ScaleUnits',
  components: {PageHeading, LibraryScalesUnitsForm},

  computed: {
    ...mapGetters(['user']),
  },
  data() {
    return {

    }
  },
  mounted() {},
  methods: {

  }
}

</script>


<style lang="scss">


</style>
