<template>
  <div
    v-if="$canAndRulesPass(user, $permissions.MASS_ACTIONS.CREATE)"
    class="mass-actions-page"
    v-loading="performingAction"
  >

    <page-heading
      heading="Массовые действия"
    >
      <span slot="help"></span>
      <span slot="right"></span>
    </page-heading>


<!--    <jobs></jobs>-->

    <el-radio-group
      v-model="massActionCurrentEntity"
      class="mass-action-entities"
      @change="setMassActionBlock"
    >
      <el-radio
        v-for="entity in $companyConfiguration.massActions.getEntities()"
        :key="entity.reportKey"
        :label="entity.reportKey"
        style="margin-right: 20px"
        border
      >
        {{entity.showName}}
      </el-radio>
    </el-radio-group>


    <builder
      v-show="massActionCurrentEntity"
      style="margin-top: 20px"
      ref="builder"
      :elements="builderElements"
      :selected-fields.sync="builderFields"
      :relations.sync="builderRelations"
      :search-fields.sync="builderSearch"
      :load-children="loadNodeRelations"

      :save-report-actions="false"
      :show-edit-mode-buttons="false"
      :show-selected-fields-area="false"
      :show-build-report-buttons="false"
      :click-node-field-to-select="false"

      @node-check-changed="nodeCheckChanged"
    ></builder>

    <report-result-modal
      v-if="!!builderFields.length"
      :builder-fields="$companyConfiguration.massActions.getReportBuilderEntityPreviewSettings(currentRootNode.key).selected_fields"
      :builder-relations="builderRelations"
      :builder-search="builderSearch"
    >
      <el-button
        slot="reference"
        size="mini"
        type="primary"
        style="width: 100%"
      >
        Результаты выбора
      </el-button>
    </report-result-modal>



    <div
      v-if="!!builderFields.length"
      class="mass-actions-page-block"
    >
      <div class="mass-actions-page-block-name">Действия</div>
      <el-radio-group
        v-model="massActionCurrentAction"
        class="mass-action-actions"
        @change="actionChanged"
      >
        <el-radio
          v-for="(actionConfig, actionKey) in $companyConfiguration.massActions.getEntityActions(massActionCurrentEntity)"
          :key="actionKey"
          :label="actionKey"
          border
          style="margin-right: 20px; margin-top: 10px"
        >
          {{actionConfig.showName}}
        </el-radio>
      </el-radio-group>
    </div>



    <div
      v-if="actionTemplate"
      class="mass-actions-page-block"
    >
      <div class="mass-actions-page-block-name">Настройки</div>

      <component
        :is="actionTemplate"
        :result="actionResult"
        :templateData="actionTemplateData"
        style="margin-top: 10px"
        @do-action="doAction"
      ></component>
    </div>

  </div>

</template>

<script>


import Builder from "../components/report/builder/Builder";
import Paginator from "../components/Paginator";
import PageHeading from "../components/PageHeading";
import SavedReports from "../components/report/SavedReports";
import Jobs from "@/components/massActions/Jobs";
import {mapGetters} from "vuex";
import ReportResultModal from "@/components/report/builder/ReportResultModal.vue";

export default {
  name: 'MassActions',
  mixins: [],
  components: {ReportResultModal, Jobs, SavedReports, PageHeading, Paginator, Builder},

  computed: {
      ...mapGetters(['user']),
    actionTemplate() {
      if( !this.massActionCurrentAction ){
        return null;
      }
      return this.$companyConfiguration.massActions.getActionTemplate(this.massActionCurrentEntity, this.massActionCurrentAction);
    },
    actionTemplateData() {
      if( !this.massActionCurrentAction ){
        return null;
      }
      return this.$companyConfiguration.massActions.getActionTemplateData(this.massActionCurrentEntity, this.massActionCurrentAction);
    },
  },
  data() {
    return {
      performingAction: false,

      builderElements: [],
      builderFields: [],
      builderRelations: [],
      builderSearch: [],

      defaultRootBlocks: [],
      currentRootNode: null,


      massActionEntities: {},
      massActionCurrentEntity: null,
      massActionCurrentAction: null,

      actionResult: null,
    }
  },

  mounted() {},
  methods: {

    loadNodeRelations(node, resolve){
      this.getBlockChildren(node.key)
        .then( blocks => {
          resolve(blocks);
        })
    },

    loadRootBlocks(forEntity){
      return this.getBlockChildren()
        .then( blocks => {
          this.defaultRootBlocks = [];
          blocks.forEach(block => {
            if( block.key === forEntity ){
              this.defaultRootBlocks.push(block);
            }
          });
        })
    },

    getBlockChildren(parent_key){
      return this.$api.report.getBlocksByParent({
        parent_key: parent_key
      })
        .then(data => {
          return data.blocks;
        })
    },


    nodeCheckChanged(node){
      if( !node.checked ){
        return;
      }

      if( node.relationChain.length === 1 ){
        this.builderFields = [{
          relationsChain: node.relationChain,
          block: node.key,
          field: 'id',
          name: 'ID'
        }];

        this.currentRootNode = node;
      }
    },



    setMassActionBlock(){
      this.massActionCurrentAction = null;
      this.builderRelations = [];
      this.builderSearch = [];
      this.builderFields = [];
      this.actionResult = null;
      this.loadRootBlocks(this.massActionCurrentEntity)
        .then(() => {
          this.builderElements = structuredClone(this.defaultRootBlocks);
          this.$refs.builder.editMode = true;
        })

    },

    actionChanged(){
      this.actionResult = null;
    },


    doAction(actionData){
      this.performingAction = true;
      this.$api.massAction.create({
        entity: this.massActionCurrentEntity,
        action: this.massActionCurrentAction,

        provider: 'report_builder',
        provider_data: {
          selected_fields: this.builderFields,
          search_fields: this.builderSearch,
        },

        action_data: actionData,
      })
        .then(data => {
          this.actionResult = data;
        })
        .finally(()=>{
          this.performingAction = false;
        })
    }

  }
}

</script>


<style lang="scss">
@import "@/assets/css/project-variables";

.mass-action-entities span.el-radio__input, .mass-action-actions span.el-radio__input {
  display: none !important;
}

.mass-action-actions{

}

.mass-action-result {
  margin-top: 20px;
}

.mass-actions-page{
  &-block{
    margin-top: 20px;

    &-name {
      font-weight: bold;
    }
  }
}

</style>
