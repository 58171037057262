export default {
    "types": {
        "required": {
            "showName": "Обязательные цели"
        }
    },
    "use_scales_units_library": true,
    "element_change_propagation_mode": 2,
    "folder_moderators_config_enabled": false,
    "folder_visibility_config_enabled": true,
    "folder_visibility_config_mode": 1,
    "folder_visibility_config_see_child": true,
    "on_import_using_visible": true,
    "on_folder_clone_clear_fields": [
        "bonus"
    ],
    "elements_table_columns": [
        {
            "label": "ID",
            "prop": "id",
            "width": 100
        },
        {
            "label": "Категория",
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementCategoryTableColumn",
            "bind": {
                "scope": "scope"
            }
        },
        {
            "label": "Название",
            "prop": "name",
            "template": "components/tableColumnTemplates/library/ElementNameTableColumn",
            "bind": {
                "scope": "scope",
                "show-folders-in-search": "templateData.$parent.$parent.$data.showFoldersInSearch"
            }
        },
        {
            "label": "Описание",
            "prop": "desc",
            "bind": {
                "scope": "scope"
            }
        },
        {
            "label": "Целевой показатель",
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementTargetTableColumn",
            "bind": {
                "scope": "scope"
            }
        },
        {
            "label": "Единица измерения",
            "prop": "scale.unit.name",
            "bind": {
                "scope": "scope"
            },
            "width": 150
        },
        {
            "label": "Целевой срок достижения",
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementDeadlineTableColumn",
            "bind": {
                "scope": "scope"
            }
        }
    ]
}