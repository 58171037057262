<template>
    <el-form
      ref="addNegotiationForm"
      size="small"
      label-position="top"
      :model="value"
      :rules="negotiationRules"
    >
      <el-form-item label="Тип" prop="type">
        <el-select
          v-model="value.type"
          style="width: 100%"
          @change="update('type', $event)"
        >
          <el-option
            v-for="(typeConfig, type) in $companyConfiguration.cardStageNegotiations.getNegotiationTypesConfig()"
            :key="type"
            :label="typeConfig.showName"
            :value="type"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Название" prop="description" v-if="value.type === 'other'">
        <el-input
          v-model="value.description"
          @input="update('description', $event)"
        >
        </el-input>
      </el-form-item>

      <el-form-item label="Ответственный" v-if="value.type === 'other'">
        <el-row type="flex" justify="space-between">
          <div>{{value.user ? value.user.full_name : ''}}</div>
          <div>
            <add-users-dialog
              single-select
              title-text="Выбор ответственного"
              add-button-text="Нажмите на строку для выбора"
              @users-selected="userSelected"
            >
              <el-button
                slot="reference"
                size="mini"
                icon="fas fa-person-booth"
              >
                Выбрать
              </el-button>
            </add-users-dialog>
          </div>
        </el-row>
      </el-form-item>

    </el-form>
</template>

<script>

import AddUsersDialog from "@/components/user/AddUsersDialog";
export default {
  name: "negotiation-edit-form",
  components: {AddUsersDialog},

  props: {
    value: {type: Object},
  },

  computed: {},
  data() {
    return {
      show: false,
      loading: false,

      negotiationRules: {
        type: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'change'}],
      },
    }
  },
  methods: {
    update(key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },

    userSelected(user){
      this.update('user', user);
    },

  }
}
</script>

<style>

</style>