<template>
  <div v-if="$canAndRulesPass(user, 'calibration.see_in_menu')">
    <page-heading :heading="pageTitle">
      <span slot="help"></span>
      <div slot="right">
        <div class="el-row--flex">
          <calibration-evaluation-to-distribution-modal
            v-if="$canAndRulesPass(user, 'calibration.edit_distribution')"
            @on-close="onDistributionModalClose"
          >
            <el-button
              slot="reference"
              size="small"
              type="primary"
              plain
              icon="fas fa-pen"
            >
              Настройка шкалы распределения
            </el-button>
          </calibration-evaluation-to-distribution-modal>
        </div>
      </div>
    </page-heading>



    <calibration-block
      v-for="i in 4"
      :key="i"
      :ref="'calibrationBlock_'+i"
      style="margin-bottom: 50px"
    ></calibration-block>


  </div>
</template>

<script>
  import ClickToEdit from "@/components/ClickToEdit";
  import PageHeading from "@/components/PageHeading.vue";
  import requestSender from "@/api/base/requestSender";
  import {mapGetters} from "vuex";
  import LibraryScalesUnitsModal from "@/components/library/LibraryScalesUnitsModal.vue";
  import CalibrationEvaluationToDistributionModal
    from "@/componentsCompany/smz/calibration/CalibrationEvaluationToDistributionModal.vue";
  import CalibrationBlock from "@/componentsCompany/smz/calibration/CalibrationBlock.vue";

  export default {
    name: 'Calibration',
    components: {
      CalibrationBlock,
      CalibrationEvaluationToDistributionModal, LibraryScalesUnitsModal, PageHeading, ClickToEdit},
    mixins: [],
    computed: {
      ...mapGetters(['user']),
      pageTitle(){
        return document.title;
      },
    },
    mounted() {
      this.getEvaluations();
    },
    data() {
      return {
        loading: false,
        evaluations: [],
      }
    },
    methods: {
      getEvaluations() {
        this.loading = true;

        requestSender('get', 'evaluation/search', {
          expand: ['permissions'].join()
        })
          .then(({evaluations}) => {
            this.evaluations = evaluations;
          })
          .finally(() => {
            this.loading = false;
          })
      },

      onDistributionModalClose(){
        for( let i=1; i<5; i++ ){
          this.$refs['calibrationBlock_'+i][0].getResult();
        }
      }
    }
  }
</script>

<style>

</style>