<template>
  <div>
    <el-card v-loading="loading">
      <h4 style="margin-top: 0">{{name}}</h4>

      <div style="display: flex; justify-content: space-between;align-items: flex-start;align-content: flex-start; gap: 20px; margin-bottom: 20px">
        <user-unique-prop-values-select
          placeholder="Локация"
          v-model="filters.locations"
          prop="location"
          multiple
          @selected="filtersChanged"
        ></user-unique-prop-values-select>
        <user-unique-prop-values-select
          placeholder="Подразделение"
          v-model="filters.subdivisions"
          prop="subdivision"
          multiple
          @selected="filtersChanged"
        ></user-unique-prop-values-select>
        <user-unique-prop-values-select
          placeholder="Цех"
          style="width: 100px"
          v-model="filters.workshops"
          prop="workshop"
          multiple
          @selected="filtersChanged"
        ></user-unique-prop-values-select>
        <user-select
          placeholder="ФИО НР"
          style="width: 400px"
          v-model="filters.direct_chief_user_id"
          :multiple="false"
          @selected="filtersChanged"
        ></user-select>
        <user-unique-prop-values-select
          v-model="filters.bands"
          placeholder="Бенд"
          style="width: 100px"
          prop="band"
          multiple
          @selected="filtersChanged"
        ></user-unique-prop-values-select>
        <el-select
          style="width: 400px"
          v-model="filters.subordinates"
          placeholder="Подчиненные"
          @change="filtersChanged"
        >
          <el-option label="Мои непосредственные подчиненные (HP)" value="immediate_subordinates_tree_direct"></el-option>
          <el-option label="Все мои подчиненные (HP)" value="all_subordinates_tree_direct"></el-option>
        </el-select>
      </div>


      <el-row :gutter="5">
        <el-col :span="4">
          <el-collapse>
            <el-collapse-item :title="'Без оценки ('+noPointsCards.length+')'">
              <div v-for="noPointsCard in noPointsCards" :key="noPointsCards.id">
                <router-link
                  style="text-decoration: none;"
                  :to="{ name: 'Card', params: { id: noPointsCard.id } }"
                  target="_blank"
                >
                  <div>{{noPointsCard.user.full_name}}</div>
                </router-link>
              </div>
            </el-collapse-item>
            <el-collapse-item :title="'Без карт ('+noCardUsers.length+')'">
              <div v-for="noCardUser in noCardUsers" :key="noCardUser.id">
                {{noCardUser.full_name}}
              </div>
            </el-collapse-item>
          </el-collapse>
          <el-button
            v-if="$canAndRulesPass(user, 'resources_evaluation.export_matrix')"
            style="width: 100%; margin-top:30px"
            size="mini"
            icon="fas fa-file-pdf"
            v-show="!exporting"
            @click="$emit('export-to-pdf')"
          >
            Выгрузить в PDF
          </el-button>
        </el-col>
        <el-col :span="20">

          <table class="human-resources-matrix-table">
            <tr>
              <td rowspan="7">
                {{$companyConfiguration.settings.getEnum('evaluation_headers')[rowEvaluationType]}}
                <el-popover
                  v-if="rowEvaluationTypeComment"
                  placement="top"
                  width="400"
                  trigger="click"
                >
                  <el-button
                    slot="reference"
                    class="table-action-button open-desc-button"
                    type="text"
                    round
                    size="mini"
                    icon="fas fa-info-circle"
                  ></el-button>
                  <div v-html="rowEvaluationTypeComment"></div>
                </el-popover>
              </td>
              <td rowspan="2" class="human-resources-matrix-table-evaluation-name-cell">
                <div v-if="evaluationNames[rowEvaluationType]">
                  {{evaluationNames[rowEvaluationType].find(e=>e.id===rowsNameConfigs[1].evaluationId).name}}
                  {{getRowStat(1)}}
                </div>
              </td>
              <td class="human-resources-matrix-table-intersection-name-cell">
                <human-resources-matrix-table-intersection-name order="1_1" :total-cards-in-table-count="totalCardsInTableCount" :cards-by-cells="cardsByCells" :table-cells-config="tableCellsConfig"></human-resources-matrix-table-intersection-name>
              </td>
              <td class="human-resources-matrix-table-intersection-name-cell">
                <human-resources-matrix-table-intersection-name order="1_2" :total-cards-in-table-count="totalCardsInTableCount" :cards-by-cells="cardsByCells" :table-cells-config="tableCellsConfig"></human-resources-matrix-table-intersection-name>
              </td>
              <td class="human-resources-matrix-table-intersection-name-cell">
                <human-resources-matrix-table-intersection-name order="1_3" :total-cards-in-table-count="totalCardsInTableCount" :cards-by-cells="cardsByCells" :table-cells-config="tableCellsConfig"></human-resources-matrix-table-intersection-name>
              </td>
            </tr>
            <tr>
              <td class="human-resources-matrix-table-cards-cell">
                <human-resources-matrix-table-user
                  v-for="(card, cardIndex) in cardsByCells['1_1']"
                  :card="card"
                  :key="card.id"
                  :index="cardIndex"
                >
                </human-resources-matrix-table-user>
              </td>
              <td class="human-resources-matrix-table-cards-cell">
                <human-resources-matrix-table-user
                  v-for="(card, cardIndex) in cardsByCells['1_2']"
                  :card="card"
                  :key="card.id"
                  :index="cardIndex"
                >
                </human-resources-matrix-table-user>
              </td>
              <td class="human-resources-matrix-table-cards-cell">
                <human-resources-matrix-table-user
                  v-for="(card, cardIndex) in cardsByCells['1_3']"
                  :card="card"
                  :key="card.id"
                  :index="cardIndex"
                >
                </human-resources-matrix-table-user>
              </td>
            </tr>

            <tr>
              <td rowspan="2" class="human-resources-matrix-table-evaluation-name-cell">
                <div v-if="evaluationNames[rowEvaluationType]">
                  {{evaluationNames[rowEvaluationType].find(e=>e.id===rowsNameConfigs[2].evaluationId).name}}
                  {{getRowStat(2)}}
                </div>
              </td>
              <td class="human-resources-matrix-table-intersection-name-cell">
                <human-resources-matrix-table-intersection-name order="2_1" :total-cards-in-table-count="totalCardsInTableCount" :cards-by-cells="cardsByCells" :table-cells-config="tableCellsConfig"></human-resources-matrix-table-intersection-name>
              </td>
              <td class="human-resources-matrix-table-intersection-name-cell">
                <human-resources-matrix-table-intersection-name order="2_2" :total-cards-in-table-count="totalCardsInTableCount" :cards-by-cells="cardsByCells" :table-cells-config="tableCellsConfig"></human-resources-matrix-table-intersection-name>
              </td>
              <td class="human-resources-matrix-table-intersection-name-cell">
                <human-resources-matrix-table-intersection-name order="2_3" :total-cards-in-table-count="totalCardsInTableCount" :cards-by-cells="cardsByCells" :table-cells-config="tableCellsConfig"></human-resources-matrix-table-intersection-name>
              </td>
            </tr>
            <tr>
              <td class="human-resources-matrix-table-cards-cell">
                <human-resources-matrix-table-user
                  v-for="(card, cardIndex) in cardsByCells['2_1']"
                  :card="card"
                  :key="card.id"
                  :index="cardIndex"
                >
                </human-resources-matrix-table-user>
              </td>
              <td class="human-resources-matrix-table-cards-cell">
                <human-resources-matrix-table-user
                  v-for="(card, cardIndex) in cardsByCells['2_2']"
                  :card="card"
                  :key="card.id"
                  :index="cardIndex"
                >
                </human-resources-matrix-table-user>
              </td>
              <td class="human-resources-matrix-table-cards-cell">
                <human-resources-matrix-table-user
                  v-for="(card, cardIndex) in cardsByCells['2_3']"
                  :card="card"
                  :key="card.id"
                  :index="cardIndex"
                >
                </human-resources-matrix-table-user>
              </td>
            </tr>

            <tr>
              <td rowspan="2" class="human-resources-matrix-table-evaluation-name-cell">
                <div v-if="evaluationNames[rowEvaluationType]">
                  {{evaluationNames[rowEvaluationType].find(e=>e.id===rowsNameConfigs[3].evaluationId).name}}
                  {{getRowStat(3)}}
                </div>
              </td>
              <td class="human-resources-matrix-table-intersection-name-cell">
                <human-resources-matrix-table-intersection-name order="3_1" :total-cards-in-table-count="totalCardsInTableCount" :cards-by-cells="cardsByCells" :table-cells-config="tableCellsConfig"></human-resources-matrix-table-intersection-name>
              </td>
              <td class="human-resources-matrix-table-intersection-name-cell">
                <human-resources-matrix-table-intersection-name order="3_2" :total-cards-in-table-count="totalCardsInTableCount" :cards-by-cells="cardsByCells" :table-cells-config="tableCellsConfig"></human-resources-matrix-table-intersection-name>
              </td>
              <td class="human-resources-matrix-table-intersection-name-cell">
                <human-resources-matrix-table-intersection-name order="3_3" :total-cards-in-table-count="totalCardsInTableCount" :cards-by-cells="cardsByCells" :table-cells-config="tableCellsConfig"></human-resources-matrix-table-intersection-name>
              </td>
            </tr>
            <tr>
              <td class="human-resources-matrix-table-cards-cell">
                <human-resources-matrix-table-user
                  v-for="(card, cardIndex) in cardsByCells['3_1']"
                  :card="card"
                  :key="card.id"
                  :index="cardIndex"
                >
                </human-resources-matrix-table-user>
              </td>
              <td class="human-resources-matrix-table-cards-cell">
                <human-resources-matrix-table-user
                  v-for="(card, cardIndex) in cardsByCells['3_2']"
                  :card="card"
                  :key="card.id"
                  :index="cardIndex"
                >
                </human-resources-matrix-table-user>
              </td>
              <td class="human-resources-matrix-table-cards-cell">
                <human-resources-matrix-table-user
                  v-for="(card, cardIndex) in cardsByCells['3_3']"
                  :card="card"
                  :key="card.id"
                  :index="cardIndex"
                >
                </human-resources-matrix-table-user>
              </td>
            </tr>


            <tr>
              <td></td>
              <td class="human-resources-matrix-table-evaluation-name-cell">
                <div v-if="evaluationNames[colEvaluationType]">{{getColName(1)}}</div>
                {{getColStat(1)}}
              </td>
              <td class="human-resources-matrix-table-evaluation-name-cell">
                <div v-if="evaluationNames[colEvaluationType]">{{getColName(2)}}</div>
                {{getColStat(2)}}
              </td>
              <td class="human-resources-matrix-table-evaluation-name-cell">
                <div v-if="evaluationNames[colEvaluationType]">{{getColName(3)}}</div>
                {{getColStat(3)}}
              </td>
            </tr>

            <tr>
              <td></td>
              <td colspan="4">
                {{$companyConfiguration.settings.getEnum('evaluation_headers')[colEvaluationType]}}
                <el-popover
                  v-if="colEvaluationTypeComment"
                  placement="top"
                  width="400"
                  trigger="click"
                >
                  <el-button
                    slot="reference"
                    class="table-action-button open-desc-button"
                    type="text"
                    round
                    size="mini"
                    icon="fas fa-info-circle"
                  ></el-button>
                  <div v-html="colEvaluationTypeComment"></div>
                </el-popover>
              </td>
            </tr>
          </table>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import {paginator} from "@/mixins/paginator";
import {mapGetters} from "vuex";
import {sorter} from "@/mixins/sorter";
import PageHeading from "@/components/PageHeading.vue";
import requestSender from "@/api/base/requestSender";
import LibraryElements from "@/components/library/LibraryElements.vue";
import LibraryFolders from "@/components/library/LibraryFolders.vue";
import HumanResourcesMatrixTableUser
  from "@/componentsCompany/smz/humanResourcesMatrix/HumanResourcesMatrixTableUser.vue";
import HumanResourcesMatrixTableIntersectionName
  from "@/componentsCompany/smz/humanResourcesMatrix/HumanResourcesMatrixTableIntersectionName.vue";
import UserUniquePropValuesSelect from "@/components/filters/users/UserUniquePropValuesSelect.vue";
import UserSelect from "@/components/filters/users/UserSelect.vue";

export default {
  name: 'HumanResourcesMatrixBlock',
  components: {
    UserSelect,
    UserUniquePropValuesSelect,
    HumanResourcesMatrixTableIntersectionName,
    HumanResourcesMatrixTableUser, LibraryFolders, LibraryElements, PageHeading},

  props:{
    name: {type: String, required: true},
    rowEvaluationType: {type: Number, required: true},
    rowEvaluationTypeComment: {type: String},
    rowPointName: {type: String, required: true},
    colEvaluationType: {type: Number, required: true},
    colEvaluationTypeComment: {type: String},
    colPointName: {type: String, required: true},

    rowsNameConfigs: {type: Object, required: true},
    colsNameConfigs: {type: Object, required: true},

    tableCellsConfig: {type: Object, required: true},
  },

  computed: {
    ...mapGetters(['user']),
    totalCardsInTableCount(){
      return this.cards.length - this.noPointsCards.length;
    }
  },


  data() {
    return {
      loading: false,
      exporting: false,
      filters: {
        locations: [],
        subdivisions: [],
        workshops: [],
        bands: [],
        direct_chief_user_id: null,
        subordinates: 'immediate_subordinates_tree_direct',
      },

      evaluationNames: {},

      cards: [],
      noPointsCards: [],
      cardsByCells:{
        '1_1': [],
        '1_2': [],
        '1_3': [],
        '2_1': [],
        '2_2': [],
        '2_3': [],
        '3_1': [],
        '3_2': [],
        '3_3': [],
      },

      noCardUsers: [],

    }
  },
  mounted() {
    this.getEvaluations();
    this.getMatrix();
  },
  methods: {
    filtersChanged(){
      this.getMatrix();
    },
    getEvaluations() {
      requestSender('get', 'evaluation/search', {})
        .then(({evaluations}) => {
          this.evaluationNames = evaluations;
        })
    },
    getColName(colIndex){
      if( !Array.isArray(this.colsNameConfigs[colIndex].evaluationId) ){
        return this.evaluationNames[this.colEvaluationType].find(e=>e.id===this.colsNameConfigs[colIndex].evaluationId).name
      } else {
        let names = [];
        this.evaluationNames[this.colEvaluationType].filter(e=>this.colsNameConfigs[colIndex].evaluationId.includes(e.id)).forEach(e => {
          names.push(e.name);
        })
        return names.join('/');
      }
    },

    getColStat(colIndex){
      let text = '';

      let colSum = this.cardsByCells['1_'+colIndex].length + this.cardsByCells['2_'+colIndex].length + this.cardsByCells['3_'+colIndex].length;
      text += '('+colSum+'/'+this.totalCardsInTableCount+')';

      if( !this.totalCardsInTableCount ){
        return text;
      }

      let percents = Math.round(colSum / this.totalCardsInTableCount * 100);
      text += ' ('+percents+'%)';

      return text;
    },
    getRowStat(rowIndex){
      let text = '';

      let rowSum = this.cardsByCells[rowIndex+'_1'].length + this.cardsByCells[rowIndex+'_2'].length + this.cardsByCells[rowIndex+'_3'].length;
      text += '('+rowSum+'/'+this.totalCardsInTableCount+')';

      if( !this.totalCardsInTableCount ){
        return text;
      }

      let percents = Math.round(rowSum / this.totalCardsInTableCount * 100);
      text += ' ('+percents+'%)';

      return text;
    },

    getMatrix(){
      this.loading = true;

      requestSender('get', 'human-resources-evaluation/get-matrix-data', {
        ...this.filters,
        matrix_id: parseInt(this.$route.params.id),

        expand: 'user, info',
      })
        .then(data => {
          this.cardsByCells = {
            '1_1': [],
            '1_2': [],
            '1_3': [],
            '2_1': [],
            '2_2': [],
            '2_3': [],
            '3_1': [],
            '3_2': [],
            '3_3': [],
          };
          this.noPointsCards = [];
          this.cards = data.cards;
          this.noCardUsers = data.no_card_users;


          this.cards.forEach(card => {
            let rowPoint = card.info[this.rowPointName];
            let colPoint = card.info[this.colPointName];

            if( !rowPoint || !colPoint ){
              this.noPointsCards.push(card);
              return;
            }

            let rowNumber = null;
            for (const [key, value] of Object.entries(this.rowsNameConfigs)) {
              if( Array.isArray(value.evaluationId) ){
                if( value.evaluationId.includes(rowPoint) ){
                  rowNumber = key;
                  break;
                }
              } else {
                if( value.evaluationId === rowPoint ){
                  rowNumber = key;
                  break;
                }
              }
            }

            let colNumber = null;
            for (const [key, value] of Object.entries(this.colsNameConfigs)) {
              if( Array.isArray(value.evaluationId) ){
                if( value.evaluationId.includes(colPoint) ){
                  colNumber = key;
                  break;
                }
              } else {
                if( value.evaluationId === colPoint ){
                  colNumber = key;
                  break;
                }
              }
            }

            if( !rowNumber || !colNumber ){
              console.log('Что-то пошло не так');
              return;
            }

            this.cardsByCells[rowNumber+'_'+colNumber].push(card);

          });

        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
}
</script>

<style lang="scss">
.human-resources-matrix-table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;

  td {
    padding: 10px;
    border: 1px solid #b4b4b4;
    border-collapse: collapse;
  }

  &-evaluation-name-cell {
    background-color: #e3e3e3;
  }
  &-intersection-name-cell {
    background-color: #f5f5f5;
  }
  &-cards-cell {
    text-align: left;
  }

}
</style>