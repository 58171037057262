<template>

  <table class="calibration-table">
    <thead>
    <tr>
      <th>{{ cells['A1'] }}</th>
      <th>{{ cells['B1'] }}</th>
      <th>{{ cells['C1'] }}</th>
      <th>{{ cells['D1'] }}</th>
      <th>{{ cells['E1'] }}</th>
      <th><b>{{ cells['F1'] }}</b></th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td><b>{{ cells['A2'] }}</b></td>
      <td>{{ cells['B2'] }} %</td>
      <td>{{ cells['C2'] }} %</td>
      <td>{{ cells['D2'] }} %</td>
      <td>{{ cells['E2'] }} %</td>
      <td><b>{{ cells['F2'] }} %</b></td>
    </tr>
    <tr>
      <td><b>{{ cells['A3'] }}</b></td>
      <td :class="{'calibration-table-cell-highlighted': cells['B3'] !== cells['B2']}">{{ cells['B3'] }} %</td>
      <td :class="{'calibration-table-cell-highlighted': cells['C3'] !== cells['C2']}">{{ cells['C3'] }} %</td>
      <td :class="{'calibration-table-cell-highlighted': cells['D3'] !== cells['D2']}">{{ cells['D3'] }} %</td>
      <td :class="{'calibration-table-cell-highlighted': cells['E3'] !== cells['E2']}">{{ cells['E3'] }} %</td>
      <td :class="{'calibration-table-cell-highlighted': cells['F3'] !== cells['F2']}"><b>{{ cells['F3'] }} %</b></td>
    </tr>
    <tr>
      <td><b>{{ cells['A4'] }}</b></td>
      <td>{{ cells['B4'] }}</td>
      <td>{{ cells['C4'] }}</td>
      <td>{{ cells['D4'] }}</td>
      <td>{{ cells['E4'] }}</td>
      <td><b>{{ cells['F4'] }}</b></td>
    </tr>
    <tr>
      <td><b>{{ cells['A5'] }}</b></td>
      <td :class="{'calibration-table-cell-highlighted': cells['B5'] !== cells['B2']}">{{ cells['B5'] }}</td>
      <td :class="{'calibration-table-cell-highlighted': cells['C5'] !== cells['C2']}">{{ cells['C5'] }}</td>
      <td :class="{'calibration-table-cell-highlighted': cells['D5'] !== cells['D2']}">{{ cells['D5'] }}</td>
      <td :class="{'calibration-table-cell-highlighted': cells['E5'] !== cells['E2']}">{{ cells['E5'] }}</td>
      <td :class="{'calibration-table-cell-highlighted': cells['F5'] !== cells['F2']}"><b>{{ cells['F5'] }}</b></td>
    </tr>
    <tr>
      <td><b>{{ cells['A6'] }}</b></td>
      <td :class="{'calibration-table-cell-actions-highlighted': cells['B6'] != 0}">{{ cells['B6'] }}</td>
      <td :class="{'calibration-table-cell-actions-highlighted': cells['C6'] != 0}">{{ cells['C6'] }}</td>
      <td :class="{'calibration-table-cell-actions-highlighted': cells['D6'] != 0}">{{ cells['D6'] }}</td>
      <td :class="{'calibration-table-cell-actions-highlighted': cells['E6'] != 0}">{{ cells['E6'] }}</td>
      <td :class="{'calibration-table-cell-actions-highlighted': cells['F6'] != 0}"><b>{{ cells['F6'] }}</b></td>
    </tr>

    </tbody>
  </table>

</template>

<script>


export default {
  name: "calibration-table",
  components: {},
  props: {
    cells: {type: Object, required: true},
  },

  data() {
    return {}
  },

  methods: {},
}
</script>

<style lang="scss">
.calibration-table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;

  th, td {
    padding: 10px;
    border: 1px solid #b4b4b4;
    border-collapse: collapse;
  }

  th {
    background-color: #f1f1f1;
  }
  
  &-cell-highlighted {
    background-color: #ffeaea;
  }

  &-cell-actions-highlighted {
    font-weight: bold;
    background-color: #ffeaea;
  }
}
</style>