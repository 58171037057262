<template>
  <div>
    <el-dialog
      title="Управление единицами измерения"
      :visible.sync="show"
      width="40%"
      top="2vh"
      :close-on-click-modal="false"
    >

      <library-scales-units-form
        ref="unitsFrom"
      ></library-scales-units-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="show = false">Закрыть</el-button>
      </span>
    </el-dialog>

    <span ref="wrapper">
      <slot name="reference"></slot>
    </span>
  </div>
</template>

<script>

import {off, on} from "element-ui/src/utils/dom";
import LibraryScalesUnitsForm from "@/components/library/LibraryScalesUnitsForm.vue";


export default {
  name: "library-scales-units-modal",
  components: {LibraryScalesUnitsForm},
  props: {},

  data() {
    return {
      loading: false,
      show: false,
      units: [],
    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  },
  watch: {},
  methods: {
    showModal(){
      this.show = true;
      if( this.$refs.unitsFrom ){
        this.$refs.unitsFrom.loadUnits();
      }
    },
  },

  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  }

}
</script>

<style>
.library-scales-unit-row{
  display: flex;
  margin-bottom: 5px;
}
</style>