export default {
    "library": {
        "folders_header": "Папки целей",
        "add_folder_button": "Добавить папку",
        "add_subfolder_button": "Добавить папку",
        "folder_create_dialog_title": "Создание папки",
        "folder_edit_dialog_title": "Редактирование папки",
        "folder_edit_dialog_name_input_label": "Название папки",
        "delete_folder_button": "Удалить папку",
        "delete_folder_confirm_title": "Вы уверены, что хотите удалить папку?",
        "duplicate_folder_button": "Клонировать папку",
        "duplicate_folder_confirm_title": "Вы уверены, что хотите клонировать папку?",
        "add_element_button": "Добавить цель в папку",
        "elements_empty_text": "В папке нет целей",
        "element_edit_form_name": "Наименование цели",
        "element_edit_form_desc": "Описание цели",
        "element_edit_form_scale_label": "Тип шкалы",
        "element_edit_form_scale_select_placeholder": "Выберите тип шкалы",
        "delete_element_used_notification": "Данная цель используется в $related_card_elements_count целях карт. Вы действительно хотите удалить ее? Эти цели в картах будут удалены"
    },
    "cards": {
        "add_cards_button": "Создать карты",
        "card_restored_message": "Карта целей успешно восстановлена"
    },
    "card": {
        "section_add_element_button": "Добавить цель",
        "section_element_name_column_placeholder": "Внесите название цели",
        "section_element_desc_column_placeholder": "Вы можете добавить описание цели, если необходимо",
        "comment_send_button_text": "Сохранить комментарий к карте"
    },
    "user": {
        "users_search_input_placeholder": "Поиск по ФИО, должности, email и перс.номеру",
        "chiefs_search_input_placeholder": "Поиск по ФИО, должности и ФИО руководителя"
    },
    "common": {
        "user_no_role_overlay_text": "Отсутствуют права доступа в Систему целеполагания"
    }
}