<template>
  <el-card>
    <el-table
      size="mini"
      :cell-class-name="getCellClass"
      :data="[user]"
    >
      <el-table-column label="" width="160">
        <template v-slot="scope" >
          <el-button
            class="scales-settings-visibility"
            size="mini"
            :icon="visible ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"
            @click="toggleBlockVisibility"
          >
            {{ visible ? 'Свернуть': 'Развернуть' }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="Позиция" prop="position"></el-table-column>
      <el-table-column label="Цех" prop="workshop"></el-table-column>
      <el-table-column label="Подразделение" prop="subdivision"></el-table-column>
      <el-table-column label="Локация" prop="location"></el-table-column>
      <el-table-column label="Бенд" prop="band" width="80"></el-table-column>
      <el-table-column label="ФИО сотрудника" prop="full_name"></el-table-column>
      <el-table-column label="Возраст" prop="age" width="100"></el-table-column>
      <el-table-column label="Риск позиции" width="150">
        <template v-slot="scope">
          {{$companyConfiguration.settings.getEnum('successor_position_risks')[scope.row.position_risk]}}
        </template>
      </el-table-column>
    </el-table>

    <div v-if="visible" style="margin-top: 20px">
      <successors-table
        :user="user"
        @new-user-position-risk="updateUserPositionRisk"
      ></successors-table>
    </div>
  </el-card>
</template>

<script>

import requestSender from "@/api/base/requestSender";
import SuccessorsTable from "@/componentsCompany/smz/successors/SuccessorsTable.vue";
import ClickToEdit from "@/components/ClickToEdit.vue";


export default {
  name: "successor-block",
  components: {ClickToEdit, SuccessorsTable},
  props: {
    user: {type: Object, required: true},
  },

  data() {
    return {
      loading: false,
      visible: false,
    }
  },

  methods: {
    toggleBlockVisibility(){
      this.visible = !this.visible;
    },
    updateUserPositionRisk(newPositionRisk){
      this.user.position_risk = newPositionRisk;
    },
    getCellClass({row, column, rowIndex, columnIndex}){
      if( column.label === 'Риск позиции' ){
        switch (row.position_risk){
          case 1:
            return 'successors-table-risk-cell-low';
          case 2:
            return 'successors-table-risk-cell-normal';
          case 3:
            return 'successors-table-risk-cell-high';
        }
        return '';
      }
      return null;
    },
  },
}
</script>

<style lang="scss">
</style>