<template>
  <div v-loading="loading">


    <import-modal
      style="margin-right: 10px"
      entity="users_selection_by_excel"
      title="Загрузка пользователей"
      close-on-success
      @import-succeed="onResponse"
    >
      <template v-slot:reference>
        <el-tooltip
          content="Импорт показателей из файла"
          placement="top"
          :open-delay="500"
        >
          <el-button
            icon="fas fa-file-import"
          >
            Загрузка из файла
          </el-button>
        </el-tooltip>
      </template>
    </import-modal>


    <div
      v-if="users.length"
      style="margin-top:10px"
    >Обработано записей из файла: {{ users.length }}</div>

  </div>
</template>

<script>

import ImportModal from "@/components/exportImport/ImportModal.vue";

export default {
  name: "load-users-from-excel",
  components: {ImportModal},
  mixins: [],

  props: {},

  data() {
    return {
      loading: false,

      users: [],
    }
  },

  methods: {
    onResponse(response){
      this.users = response;
      this.$emit('users-loaded', this.users);
    },
  }
}
</script>

<style>

</style>
