<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="show"
      :width="width"
      top="2vh"
      :close-on-click-modal="false"
    >
      <el-row>
        <el-table
          style="width: 100%"
          :data="elements"
          empty-text="Нет истории"
          v-loading="loading"
          size="mini"
        >
          <el-table-column prop="user.full_name" label="Кто" width="230" align="center"></el-table-column>
          <el-table-column v-if="!hideTypeFullName" prop="type_full_name" label="Действие" width="130" align="center"></el-table-column>
          <el-table-column v-if="showModelFullName" prop="model_full_name" label="Сущность" align="center"></el-table-column>
          <el-table-column v-if="!hideFieldFullName" prop="field_full_name" label="Поле" align="center"></el-table-column>
          <el-table-column prop="old_value" label="До" align="center"></el-table-column>
          <el-table-column prop="new_value" label="После" align="center"></el-table-column>
          <el-table-column prop="created_at" label="Дата" align="center">
            <template slot-scope="scope">
              {{ new Date(scope.row.created_at * 1000).toLocaleString($companyConfiguration.helpers.getDateLocale()) }}
            </template>
          </el-table-column>

        </el-table>

        <paginator
          :paginator.sync="paginator"
          @page-changed="showHistory"
        ></paginator>
      </el-row>

      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="show = false">Закрыть</el-button>
            </span>
    </el-dialog>

    <span ref="wrapper">
            <slot name="reference"></slot>
        </span>
  </div>
</template>

<script>

import {off, on} from "element-ui/src/utils/dom";
import Paginator from "./Paginator";

export default {
  name: "history-modal",
  components: {Paginator},
  props: {
    method: Function,
    params: Object,
    title: {
      type: String,
      default: 'История изменений'
    },
    width: {
      type: String,
      default: '95%'
    },
    showModelFullName: {
      type: Boolean,
      default: false
    },
    hideFieldFullName: {
      type: Boolean,
      default: false
    },
    hideTypeFullName: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      show: false,
      elements: [],
      loading: false,
      paginator: {page: 1},
    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showHistory);
    }
  },
  watch: {},
  methods: {
    showHistory(e, params=null) {
      this.loading = true;
      this.elements = [];
      this.show = true;

      const paramsToSend = params ? params : this.params;

      paramsToSend.page = this.paginator.page;
      this.method(paramsToSend)
        .then(({data, paginator}) => {
          this.elements = data.history;
          this.paginator = paginator;
        })
        .finally(() => {
          this.loading = false;
        })
    }
  },

  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showHistory);
    }
  }

}
</script>

<style>

</style>