import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {

        getCustomChiefsByType(payload){
            return requestSender('get', 'orgstruct/get-custom-chiefs-by-type', payload);
        },
        setCustomChief(payload){
            return requestSender('post', 'orgstruct/set-custom-chief', payload);
        },
        unsetCustomChief(payload){
            return requestSender('post', 'orgstruct/unset-custom-chief', payload);
        },


        getInit(payload){
            return requestSender('get', 'orgstruct/get-init', payload);
        },
        getParent(payload){
            return requestSender('get', 'orgstruct/get-parent', payload);
        },
        getChild(payload){
            return requestSender('get', 'orgstruct/get-child', payload);
        },

    }
}